var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 container-fluid p-0"},[_c('b-container',{class:[
      'ul-container h-100',
      {
        'ul-container__tour': _vm.vueTourStatus,
        'ul-container__fullscreen': _vm.isFullScreenEnabled,
        'ul-container__exitscreen': _vm.isExitScreenEnabled,
        'ul-container__alert': _vm.isSystemAlertEnabled
      }
    ],staticStyle:{"padding":"0px"},attrs:{"fluid":""}},[(_vm.isSystemAlertEnabled)?_c('SystemAlert',{attrs:{"isFixed":""}}):_vm._e(),_c('div',{staticClass:"ul-header"},[_c('b-row',{class:[
          'ul-header__row',
          {
            'ul-header__fade-out':
              _vm.hideNavbar && !_vm.vueTourStatus && !_vm.isVleSideNavOpen
          }
        ]},[_c('b-col',{staticClass:"text-left pm-0",attrs:{"cols":"4","sm":"2","lg":"3","md":"2"}},[_c('div',{staticClass:"ul-header__left"},[_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.cancel-modal",modifiers:{"cancel-modal":true}}],staticClass:"ul-header__exit d-hide__mobile",attrs:{"id":"selfPlacedExit"}},[_c('img',{staticClass:"c-pointer",attrs:{"src":require("@/assets/images/vle/exit-icon.svg")}}),_c('p',{staticClass:"ul-exit"},[_vm._v(" "+_vm._s(_vm.$t("vle.exit_vle"))+" ")])]),(_vm.$route.name !== 'ViewPost')?_c('span',{staticClass:"ul-header__b1 d-hide__mobile"}):_vm._e(),(_vm.$route.name !== 'ViewPost')?_c('div',{staticClass:"ul-header__modules",attrs:{"id":"selfPlacedNavbarIcon"}},[_c('div',{class:[
                  'ul-header__modules--div',
                  {
                    'ul-header__modules--open': _vm.isVleSideNavOpen,
                    'ul-header__forums--open': _vm.getCurrentMenuItem === 'forums'
                  }
                ],on:{"click":function($event){return _vm.$store.commit('TOGGLE_VLE_SIDE_NAV')}}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"src":_vm.moduleListSrc,"alt":"modules","title":_vm.tooltipTitle}})])]):_vm._e()])]),_c('b-col',{attrs:{"cols":"4","sm":"4","lg":"5","md":"6"}},[_c('FooterMenu',{attrs:{"variant":"primary","id":"selfPlacedHeaderTabs"}})],1),_c('b-col',{class:[
            'pm-0',
            {
              'ul-header__timer': _vm.showTimedAssessment
            }
          ],attrs:{"cols":"4","lg":"3","md":"3"}},[_c('div',{class:[
              'd-flex justify-content-end align-items-center mobile-content__start',
              {
                'ul-header__timer': _vm.showTimedAssessment
              }
            ]},[(_vm.getCurrentMenuItem === 'modules')?_c('div',{class:{
                'ul-header__timer': _vm.showTimedAssessment
              }},[(_vm.getTimedAssessmentFlag)?_c('TimerComponent',{staticClass:"sp-header__timer-component",attrs:{"duration":_vm.getTimedData.duration,"state":_vm.getTimedData.state},on:{"onFinish":_vm.autoSubmitAssessment}}):_c('TopNav',{staticClass:"d-hide__mobile",on:{"onFinish":_vm.handleOnFinish}})],1):_vm._e(),(_vm.getCurrentMenuItem === 'forums')?_c('LxpButton',{staticClass:"d-hide__mobile add-post__btn",on:{"click":_vm.showAddPostForm}},[_vm._v(" "+_vm._s(_vm.$t("discussions.post.add"))+" ")]):_vm._e(),_c('div',{ref:"expandWalkthrough",staticClass:"ul-header__right",attrs:{"id":"expandWalkthrough"}},[_c('div',{staticClass:"ul-header__expand d-hide__mobile",on:{"click":_vm.toggleFullScreen}},[_c('img',{attrs:{"src":_vm.isFullScreenEnabled
                      ? require('@/assets/images/vle/exit-full-screen.svg')
                      : require('@/assets/images/vle/full-screen.svg'),"alt":"expand","id":"fullscreen"}}),_c('b-tooltip',{attrs:{"target":"fullscreen","triggers":"hover"}},[(_vm.isFullScreenEnabled)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("vle.full_screen.exit_fullscreen"))+" "),_c('span',{staticClass:"ul-header__expand--tooltip"},[_c('img',{attrs:{"src":require("@/assets/images/vle/keyboard.svg")}}),_vm._v("Esc")])]):_c('span',[_vm._v(_vm._s(_vm.$t("vle.full_screen.enter_fullscreen")))])])],1),_c('div',{staticClass:"vle__header-custom-dropdown selfpaced__menu--btn-focus d-hide__mobile"},[_c('b-dropdown',{ref:"walkthrowDropdown",staticClass:"custom-dropdown",attrs:{"right":"","variant":"link","toggle-class":"text-decoration-none","id":"walkthrowDropdown","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('Icon',{staticClass:"three-dots-vertical",attrs:{"iconName":"three-dots-vertical","rounded":false,"variant":"dark","id":"selfPlacedMenuIcon"}})]},proxy:true}])},[[_c('b-dropdown-item',{staticClass:"custom-dropdown-item",on:{"click":_vm.goToCouresData}},[_c('img',{attrs:{"src":require("@/assets/images/wrapper-components/alert/information.svg"),"alt":"about"}}),_c('span',{staticClass:"vle__list-title"},[_vm._v(_vm._s(_vm.$t("vle.vle_menu.about")))])]),_c('b-dropdown-item',{staticClass:"custom-dropdown-item",on:{"click":_vm.startTour}},[_c('img',{attrs:{"src":require("@/assets/images/icons/walkthrough.svg"),"alt":"walkthrough"}}),_c('span',{staticClass:"vle__list-title"},[_vm._v(_vm._s(_vm.$t("vle.vle_menu.walkthrough")))])]),(
                        _vm.getSelectedCourse.is_enrolled &&
                          _vm.isCourseStarted === false &&
                          !_vm.getSelectedCourse.course_completed &&
                          !_vm.isCourseEnded
                      )?_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.unenroll-modal",modifiers:{"unenroll-modal":true}}],staticClass:"unenroll custom-dropdown-item"},[_c('div',{staticClass:"ac-mc__list",staticStyle:{"color":"#FF4040"}},[_c('img',{staticClass:"unenroll-image",attrs:{"src":require("@/assets/images/icons/unenroll-module.svg"),"alt":"unenroll"}}),_c('span',{staticClass:"vle__list-title"},[_vm._v(_vm._s(_vm.$t("course.unenroll.title")))])])]):_vm._e()]],2)],1),_c('div',{staticClass:"d-show__mobile"},[_c('VleMobileHeader',{attrs:{"unEnroll":_vm.showUnenroll},on:{"walkThorugh":_vm.startTour}})],1)])],1)])],1)],1),_c('b-row',{class:[
        'ul-content',
        {
          'ul-content__scorm': _vm.isScormUnit && _vm.getCurrentMenuItem === 'modules'
        }
      ],attrs:{"id":"ul-content__main"}},[_c('b-col',{staticClass:"ul-bottom__col p-0",attrs:{"md":"12"}},[_vm._t("bottom-section")],2)],1)],1),_c('Modals',{on:{"startTour":_vm.startTour,"navigateTo":_vm.navigateTo}}),_c('Toast',{attrs:{"id":"fullscreen-toast","title":_vm.$t('vle.full_screen.title'),"imgPath":require('@/assets/images/vle/timer/timer-info.svg'),"description":_vm.$t('vle.full_screen.description'),"autoHide":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }