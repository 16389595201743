<template>
  <div class="py-1">
    <div class="d-flex footer-content" id="selfPlacedPagination">
      <!-- Previous -->
      <div
        class="footer-icon"
        :class="{
          disabled: !showHeaderNav || (isFirstSequence && isFirstUnit)
        }"
        @click="previousUnit"
      >
        <img
          src="@/assets/images/left-arrow.svg"
          :disabled="isFirstSequence && isFirstUnit"
          width="10px"
          height="10px"
        />
      </div>
      <span class="top-icon-style">{{ getCourseUnitIndex }}</span>
      <img src="@/assets/images/slash.svg" width="10px" height="15px" />
      <span class="top-icon-style">{{ getAllUnitIds.length }}</span>
      <!-- Next -->
      <div class="footer-icon" @click="nextUnit($route.params.id)">
        <img src="@/assets/images/right-arrow.svg" width="10px" height="10px" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import nextUnit from "@/components/SelfPaced/mixins/nextUnit.js";
import previousUnit from "@/components/SelfPaced/mixins/previousUnit.js";
import TIMED_ASSESSMENT_MODAL_IDS from "./TimedAssessment/constants";
export default {
  mixins: [nextUnit, previousUnit],
  data() {
    return {
      TIMED_ASSESSMENT_MODAL_IDS: {}
    };
  },
  created() {
    this.TIMED_ASSESSMENT_MODAL_IDS = TIMED_ASSESSMENT_MODAL_IDS;
  },
  computed: {
    ...mapGetters([
      "isFirstSequence",
      "isFirstUnit",
      "showHeaderNav",
      "getCourseUnitIndex",
      "getAllUnitIds"
    ])
  }
};
</script>
<style lang="scss" scoped>
.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-radius: 100px;
  width: 145px;
  height: 37px;
  background: $brand-primary-75;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.footer-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  &:hover {
    background: $brand-neutral-0;
    border-radius: 100px;
  }
  &:active {
    background: $brand-primary-100;
    border-radius: 100px;
  }
}
.top-icon-style {
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.top-icon-style {
  padding-left: 4px;
  color: $brand-primary-700;
}
</style>
