var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-list__wrapper"},[_c('vle-accordion',{attrs:{"open":_vm.open,"module":_vm.module},on:{"toggle":_vm.onToggle},scopedSlots:_vm._u([{key:"accordion-title",fn:function(){return [_c('div',{staticClass:"module-list__header",class:{ 'module-list__header--complete': _vm.module.complete }},[_c('div',{staticClass:"module-list__header-title"},[_vm._v(" "+_vm._s(_vm.module.title)+" ")]),_c('span',{staticClass:"module-list__header-sub-title"},[_vm._v(_vm._s(_vm.currentModuleNo)+" / "+_vm._s(_vm.totalModule))])])]},proxy:true},{key:"accordion-body",fn:function(){return _vm._l((_vm.module.sequences),function(sequence,index){return _c('div',{key:index,staticClass:"module-list__content"},[_c('div',{staticClass:"module-list__sub-header"},[_c('div',{staticClass:"module-list__sub-header-title"},[_vm._v(" "+_vm._s(sequence.title)+" ")])]),_c('div',{staticClass:"module-list__container"},[_c('ul',_vm._l((sequence.units),function(unit,index){return _c('li',{key:index,staticClass:"module-list__section c-pointer",class:{
                'module-list__section--active': _vm.getCurrentUnitId === unit.id,
                'module-list__section--completed': unit.complete === true,
                'module-list__section--locked':
                  _vm.isPrerequisiteCompleted(sequence) !== true,
                'module-list__section--only-child': sequence.units.length == 1
              },attrs:{"id":`module-list__${unit.id}-${index}`},on:{"click":function($event){return _vm.selectModule(sequence, unit.id)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"sp__module-align align-self-center"},[(_vm.isPrerequisiteCompleted(sequence) !== true)?_c('img',{attrs:{"src":require('@/assets/images/module-list/unit-locked.svg'),"alt":"unit locked"}}):(_vm.isUnitCompleted(unit))?_c('img',{attrs:{"src":require('@/assets/images/module-list/unit-complete.svg'),"alt":"unit complete"}}):(_vm.getCurrentUnitId === unit.id)?_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.allProfile.profile_image_url
                        ? _vm.allProfile.profile_image_url
                        : require('@/assets/images/module-list/avatar.svg'),"alt":"avatar","width":"24px","height":"24px","id":"profile-pic"}}):_c('img',{attrs:{"src":_vm.getUnitIcon(unit.type, _vm.getCurrentUnitId === unit.id),"alt":unit.type}})]),_c('div',{staticClass:"sp__module-align--title"},[_c('p',[_vm._v(_vm._s(unit.title))]),_c('span',[_vm._v(_vm._s(_vm.getUnitType(unit.type)))])])]),(_vm.isPrerequisiteCompleted(sequence) === false)?_c('b-tooltip',{attrs:{"target":`module-list__${unit.id}-${index}`,"triggers":"hover focus","placement":"top"}},[_c('div',{staticClass:"ml-cd__tooltip--inner"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("course.modules.list"))+" ")])])]):_vm._e()],1)}),0)])])})},proxy:true},(_vm.module.complete === true)?{key:_vm.dynamicSlotName,fn:function(){return [_c('div',{class:[
          'module-list__completed--div',
          { 'module-list__container--open': _vm.open }
        ]},[_c('p',{staticClass:"lx-section-completed"},[_vm._v(" "+_vm._s(_vm.$t("my_learning.tab.completed"))+" ")]),_c('img',{attrs:{"src":require("@/assets/images/course-details/check-circle-fill.svg"),"alt":"completed"}})])]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }