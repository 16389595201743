import store from "../../../store";
export default {
  methods: {
    nextUnit(courseID) {
      // check if its last unit or last sequence
      if (store.getters.isLastSequence && store.getters.isLastUnit) {
        this.$emit("onFinish");
      } else {
        if (!store.getters.isLastUnit) {
          this.updateUnitPosition(store.getters.getCurrentUnitIndex + 2);

          store.commit(
            "SET_CURRENT_UNIT_ID",
            store.getters.unitsBySequenceId[
              store.getters.getCurrentUnitIndex + 1
            ].id
          );
        } else {
          // Store the current nextSequence in a local variable
          // and use it for the later function call as it may
          // be changed dynamically post the API call
          let nextSequence = store.getters.getNextSequence;

          if (
            store.getters.isPrerequisiteCompleted(
              store.getters.getNextSequence
            ) === true
          ) {
            this._goToNextModule(nextSequence);
          } else {
            // show loader
            store.commit("SET_SHOW_FULL_PAGE_LOADER", true);
            // get the completion status from server
            store
              .dispatch("getACourse", {
                course_id: courseID,
                version: "v2"
              })
              .then(() => {
                // hide the loader
                store.commit("SET_SHOW_FULL_PAGE_LOADER", false);
                // check the prerequisite status
                if (
                  store.getters.isPrerequisiteCompleted(nextSequence) === true
                ) {
                  this._goToNextModule(nextSequence);
                } else {
                  this.createToast(
                    this.$t("vle.error.prerequisite.message"),
                    "Error",
                    "warning"
                  );
                }
              });
          }
        }
      }
    },
    updateUnitPosition(position) {
      store.dispatch("updateUnitPosition", {
        courseId: store.getters.getSelectedCourse.id,
        sequenceId: store.getters.getCurrentSequenceId,
        position: position
      });
    },

    // prefix with _ for internal methods
    _goToNextModule(nextSequence) {
      // update the current section for micro-credential check
      store.commit("SET_MODULE_TO_CHECK_MC", store.getters.getCurrentSection);

      store.commit("GO_TO_NEXT_MODULE", nextSequence);
    }
  }
};
