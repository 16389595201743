<template>
  <div class="ta-modals">
    <!-- Submit assessment modal -->
    <b-modal
      :id="TIMED_ASSESSMENT_MODAL_IDS.CONFIRMATION_MODAL"
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :dir="language === 'ar' ? 'rtl' : 'ltr'"
    >
      <template #modal-header>
        <div class="lxp-modal__header">
          <div
            class="close-icon-styles"
            @click="closeModal(TIMED_ASSESSMENT_MODAL_IDS.CONFIRMATION_MODAL)"
          >
            <img src="@/assets/images/close.svg" alt="close" />
          </div>
        </div>
      </template>
      <div class="lxp-modal__body">
        <img
          src="@/assets/images/vle/submit-assessment.svg"
          class="lxp-modal__content--img"
          alt="information"
        />
        <h3 class="lxp-modal__title">
          {{ $t("vle.modal.timed_assessment.confirmation.heading") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("vle.modal.timed_assessment.confirmation.content") }}
        </p>
      </div>
      <template #modal-footer>
        <div class="lxp-modal__footer">
          <div class="d-flex justify-content-center">
            <template v-if="submitAssessment">
              <LxpButton
                block
                variant="success"
                class="um__conformation--button"
              >
                <img
                  class="um__conformation-submit-icon"
                  src="@/assets/images/vle/submitted.svg"
                  alt="submitted"
                />
                {{ $t("vle.timed_assessment.submitted") }}
              </LxpButton>
            </template>
            <template v-else>
              <LxpButton
                variant="outline-primary"
                class="um__conformation--button"
                @click="
                  closeModal(TIMED_ASSESSMENT_MODAL_IDS.CONFIRMATION_MODAL)
                "
              >
                {{ $t("vle.modal.button.back_to_assessment") }}
              </LxpButton>
              <LxpButton
                @click="onSubmit"
                class="um__conformation-submit--button"
              >
                {{ $t("vle.submit") }}
              </LxpButton>
            </template>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- display alert modal when timer is over -->
    <b-modal
      :id="TIMED_ASSESSMENT_MODAL_IDS.ALERT_MODAL"
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :dir="language === 'ar' ? 'rtl' : 'ltr'"
    >
      <template #modal-header>
        <div class="d-flex justify-content-end w-100">
          <div
            class="close-icon-styles"
            @click="closeModal(TIMED_ASSESSMENT_MODAL_IDS.ALERT_MODAL)"
          >
            <img src="@/assets/images/close.svg" alt="close" />
          </div>
        </div>
      </template>
      <div class=" lxp-modal__body">
        <img
          src="@/assets/images/vle/timer/assignment-late.svg"
          alt="assignment late"
        />
        <h3 class="lxp-modal__title">
          {{ $t("vle.modal.timed_assessment.finsih.heading") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("vle.modal.timed_assessment.finsih.content") }}
        </p>
      </div>

      <template #modal-footer>
        <div class="lxp-modal__footer">
          <div class="d-flex justify-content-center">
            <LxpButton
              variant="primary"
              class="um__alert-modal--button"
              @click="
                $bvModal.hide(TIMED_ASSESSMENT_MODAL_IDS.ALERT_MODAL);
                $emit('onFinish');
              "
            >
              {{ nextBtnText }}
            </LxpButton>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- microcredential modal for subsection part  -->

    <b-modal
      :id="TIMED_ASSESSMENT_MODAL_IDS.SUBSECTION_MODAL"
      centered
      size="md"
      :dir="language === 'ar' ? 'rtl' : 'ltr'"
    >
      <template #modal-header="{ close }">
        <div class="lxp-modal__header">
          <div class="close-icon-styles">
            <img src="@/assets/images/close.svg" alt="close" @click="close" />
          </div>
        </div>
      </template>

      <div class="lxp-modal__body">
        <img
          src="@/assets/images/vle/timer/credential.svg"
          alt="credential illustration"
          width="64"
          height="64"
          class="lxp-modal__content--img"
        />
        <h3 class="lxp-modal__title">{{ $t("vle.success.title") }}!</h3>
        <h5>{{ $t("vle.success.microcredential.subtitle") }}</h5>
        <p class="lxp-modal__subtitle">
          {{ $t("vle.success.microcredential.message") }}
        </p>
      </div>
      <template slot="modal-footer">
        <div class="lxp-modal__footer">
          <LxpButton
            variant="outline-primary"
            className="mr-2"
            @click="
              navigateTo({
                path: '/my-learning',
                query: { tab: 2 }
              })
            "
            >{{ $t("my_learning.tab.my_achievements") }}
          </LxpButton>
          <LxpButton
            variant="primary"
            className="text-uppercase"
            @click="
              $bvModal.hide(TIMED_ASSESSMENT_MODAL_IDS.SUBSECTION_MODAL);
              $emit('onFinish');
            "
            >{{ nextBtnText }}</LxpButton
          >
        </div>
      </template>
    </b-modal>

    <!-- success modal for timed assessment -->
    <b-modal
      :id="TIMED_ASSESSMENT_MODAL_IDS.SUCCESS_MODAL"
      centered
      size="md"
      :dir="language === 'ar' ? 'rtl' : 'ltr'"
    >
      <template #modal-header="{ close }">
        <div class="lxp-modal__header">
          <div class="close-icon-styles">
            <img src="@/assets/images/close.svg" alt="close" @click="close()" />
          </div>
        </div>
      </template>

      <div class="lxp-modal__body">
        <img
          src="@/assets/images/vle/timer/assessment-complete.svg"
          alt="Timer"
          width="48"
          height="48"
          class="lxp-modal__content--img"
        />
        <h3 class="lxp-modal__title">
          {{ $t("vle.modal.timed_assessment.success.heading") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("vle.assessment.timed.modal.success.content") }}
        </p>
      </div>

      <template slot="modal-footer">
        <div class="lxp-modal__footer">
          <LxpButton
            variant="primary"
            className="text-uppercase"
            @click="
              $bvModal.hide(TIMED_ASSESSMENT_MODAL_IDS.SUCCESS_MODAL);
              $emit('onFinish');
            "
            >{{ nextBtnText }}</LxpButton
          >
        </div>
      </template>
    </b-modal>

    <!-- Incomplete modal for timed assessment -->
    <b-modal
      :id="TIMED_ASSESSMENT_MODAL_IDS.INCOMPLETE_MODAL"
      centered
      size="md"
      :dir="language === 'ar' ? 'rtl' : 'ltr'"
    >
      <template #modal-header="{ close }">
        <div class="lxp-modal__header">
          <div class="close-icon-styles">
            <img src="@/assets/images/close.svg" alt="close" @click="close()" />
          </div>
        </div>
      </template>

      <div class="lxp-modal__body">
        <img
          src="@/assets/images/vle/timer/assessment-incomplete.svg"
          alt="Timer"
          width="48"
          height="48"
        />
        <h3 class="lxp-modal__title">
          {{ $t("vle.assessment.timed.modal.incomplete.heading") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("vle.assessment.timed.modal.incomplete.content") }}
        </p>
        <span class="lxp-content__note">
          {{ $t("vle.assessment.timed.modal.incomplete.note") }}
        </span>
      </div>

      <template slot="modal-footer">
        <div class="lxp-modal__footer">
          <LxpButton
            variant="outline-primary"
            className="text-uppercase"
            @click="
              $bvModal.hide(TIMED_ASSESSMENT_MODAL_IDS.INCOMPLETE_MODAL);
              $emit('onFinish');
            "
            >{{ nextBtnText }}</LxpButton
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";
import TIMED_ASSESSMENT_MODAL_IDS from "./constants.js";
export default {
  components: { LxpButton },
  data() {
    return {
      TIMED_ASSESSMENT_MODAL_IDS: {},
      submitAssessment: false
    };
  },
  created() {
    this.TIMED_ASSESSMENT_MODAL_IDS = TIMED_ASSESSMENT_MODAL_IDS;
  },
  computed: {
    ...mapGetters(["isLastSequence", "isLastUnit", "language"]),
    nextBtnText() {
      return this.isLastSequence && this.isLastUnit
        ? this.$t("vle.timed_assessment.finish")
        : this.$t("vle.next");
    }
  },
  methods: {
    onSubmit() {
      this.submitAssessment = true;
      setTimeout(() => {
        this.$emit("onSubmit");
      }, 1000);
    },
    closeModal(id) {
      this.$bvModal.hide(id);
    },
    navigateTo(option) {
      this.$router.push(option).catch(() => {});
    }
  }
};
</script>
<style lang="scss">
// modal
.ta-modals {
  @media (min-width: 576px) {
    .modal-body {
      padding: 1rem 40px;
    }
  }
}
</style>
