import store from "../../../store";
export default {
  methods: {
    previousUnit() {
      if (!store.getters.isFirstUnit && store.getters.getCurrentUnitIndex > 0) {
        this.updateUnitPosition(store.getters.getCurrentUnitIndex);
        store.commit(
          "SET_CURRENT_UNIT_ID",
          store.getters.unitsBySequenceId[store.getters.getCurrentUnitIndex - 1]
            .id
        );
      } else {
        // Store the current previousSequence in a local variable
        // and use it for the later function call as it may
        // be changed dynamically post the API call
        let previousSequence = store.getters.getPreviousSequence;
        if (
          store.getters.isPrerequisiteCompleted(
            store.getters.getPreviousSequence
          ) === true
        ) {
          this._goToPreviousModule(previousSequence);
        } else {
          // show loader
          store.commit("SET_SHOW_FULL_PAGE_LOADER", true);
          // get the completion status from server
          store
            .dispatch("getACourse", {
              course_id: this.$route.params.id,
              version: "v2"
            })
            .then(() => {
              // hide the loader
              store.commit("SET_SHOW_FULL_PAGE_LOADER", false);
              // check the prerequisite status
              if (
                store.getters.isPrerequisiteCompleted(previousSequence) === true
              ) {
                this._goToPreviousModule(previousSequence);
              } else {
                this.createToast(
                  this.$t("vle.error.prerequisite.message"),
                  "Error",
                  "warning"
                );
              }
            });
        }
      }
    },
    // prefix with _ for internal methods
    _goToPreviousModule(previousSequence) {
      // update the current section for micro-credential check
      this.$store.commit("SET_MODULE_TO_CHECK_MC", this.getCurrentSection);
      if (previousSequence) {
        this.$store.commit("GO_TO_PREVIOUS_MODULE", previousSequence);
      }
    }
  }
};
