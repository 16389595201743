import TIMED_ASSESSMENT_MODAL_IDS from "@/components/SelfPaced/TimedAssessment/constants";

export default {
  data() {
    return {
      TIMED_ASSESSMENT_MODAL_IDS: {}
    };
  },
  created() {
    this.TIMED_ASSESSMENT_MODAL_IDS = TIMED_ASSESSMENT_MODAL_IDS;
  },
  methods: {
    autoSubmitAssessment() {
      this.$store.commit("SET_SHOW_FULL_PAGE_LOADER", true);
      this.submitAssessment().then(() => {
        this.$store.commit("SET_SHOW_FULL_PAGE_LOADER", false);
        this.$bvModal.show(this.TIMED_ASSESSMENT_MODAL_IDS.ALERT_MODAL);
      });
    },
    submitAssessment() {
      const params = {
        courseId: this.$route.params.id,
        sequenceId: this.getCurrentSequenceId,
        state: "submitted"
      };
      return Promise.all([
        this.$store.dispatch("getUnitsBySequenceId", this.getCurrentSequenceId),
        this.$store.dispatch("updateTimedAssessment", params)
      ]);
    }
  }
};
