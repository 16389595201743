<template>
  <div class="sp-completed__wrapper">
    <div class="sp-completed__container">
      <div class="sp-completed__row justify-content-center">
        <img
          :src="
            isCurrentUnitCompleted
              ? require('@/assets/images/vle/timer/assessment-complete.svg')
              : require(`@/assets/images/vle/timer/assessment-incomplete.svg`)
          "
          alt="Timer"
          width="auto"
          height="auto"
        />
        <template v-if="isCurrentUnitCompleted">
          <h4 class="sp-completed__heading">
            {{ $t("vle.timed_assessment.completed.title") }}
          </h4>
          <p class="sp-completed__text">
            {{ $t("vle.timed_assessment.completed.subtitle") }}
          </p>
        </template>
        <template v-else>
          <h4 class="sp-completed__heading">
            {{ $t("vle.timed_assessment.incomplete.title") }}
          </h4>
          <p class="sp-completed__text">
            {{ $t("vle.timed_assessment.incomplete.subtitle") }}
          </p>
        </template>
        <LxpButton
          className="sp-completed__button"
          @click="nextUnit($route.params.id)"
          >{{ nextBtnText }}
        </LxpButton>
      </div>
      <div class="sp-incomplete__note" v-if="!isCurrentUnitCompleted">
        <img
          src="@/assets/images/vle/timer/timer-info.svg"
          alt="info"
          width="26"
          height="26"
        />
        <p class="sp-incomplete__note-text">
          {{ $t("vle.assessment.timed.modal.incomplete.note") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import nextUnit from "@/components/SelfPaced/mixins/nextUnit.js";
import { LxpButton } from "didactica";
export default {
  mixins: [nextUnit],
  components: { LxpButton },
  computed: {
    ...mapGetters(["isCurrentUnitCompleted", "isLastSequence", "isLastUnit"]),
    nextBtnText() {
      return this.isLastSequence && this.isLastUnit
        ? this.$t("vle.timed_assessment.finish")
        : this.$t("general.next");
    }
  }
};
</script>

<style lang="scss" scoped>
.sp-completed__wrapper {
  height: 100%;
  .sp-completed__container {
    height: 100%;
    .sp-completed__row {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      border-radius: 8px;
      padding: 24px;
      .sp-completed__heading {
        @include subtitle-large($brand-neutral-900);
        margin: 24px 0 8px;
      }
      .sp-completed__text {
        @include body-regular;
        margin-bottom: 24px;
      }
      .sp-completed__button {
        margin: 0 auto;
        width: 166px;
      }
    }
    .sp-incomplete__note {
      display: flex;
      align-items: center;
      margin-top: 40px;
      .sp-incomplete__note-text {
        margin-left: 10px;
        @include label-large($brand-neutral-700, 500);
        margin-bottom: 0;
      }
    }
  }
}
</style>
