<template>
  <div class="co-modals">
    <!-- Microcredential modal -->
    <b-modal
      size="md"
      centered
      :no-close-on-backdrop="true"
      :id="USER_LAYOUT_MODAL_IDS.MICROCREDENTIAL_MODAL"
    >
      <template #modal-header>
        <div class="d-flex c-pointer">
          <img
            src="@/assets/images/close.svg"
            alt="close"
            @click="closeModal(USER_LAYOUT_MODAL_IDS.MICROCREDENTIAL_MODAL)"
            width="14"
            height="14"
          />
        </div>
      </template>
      <div class="cd-enroll__div cd-main">
        <div class="cd-main__sec">
          <b-row class="h-100 d-flex align-items-center justify-content-center">
            <b-col md="12" class="h-100">
              <img
                src="@/assets/images/illustration/credential-illustraion.svg"
                alt="credential illustration"
                width="105"
                class="mb-5 mt-2"
              />
              <h3>{{ $t("vle.success.title") }}!</h3>
              <h5>{{ $t("vle.success.microcredential.subtitle") }}</h5>
              <p class="cd-sec__des">
                {{ $t("vle.success.microcredential.message") }}
              </p>
              <div class="cd-sec__additional">
                <b-row>
                  <b-col class="cd-sec__footer">
                    <div>
                      <b-button
                        pill
                        class="ob-btn ob-btn-primary ml-3 mb-3 d-block"
                        @click="
                          $emit('navigateTo', {
                            path: '/my-learning',
                            query: { tab: 2 }
                          })
                        "
                        >{{ $t("vle.success.microcredential.button") }}
                      </b-button>
                      <b-button
                        class="ob-btn btn ob-btn-secondary"
                        @click="
                          $bvModal.hide(
                            USER_LAYOUT_MODAL_IDS.MICROCREDENTIAL_MODAL
                          )
                        "
                        >{{ $t("general.dismiss") }}</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
    <!-- unfinish modal -->
    <b-modal
      :id="USER_LAYOUT_MODAL_IDS.UNFINISH_MODAL"
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <template #modal-header>
        <div class="lxp-modal__header">
          <div
            class="close-icon-styles"
            @click="closeModal(USER_LAYOUT_MODAL_IDS.UNFINISH_MODAL)"
          >
            <img src="@/assets/images/close.svg" alt="close" />
          </div>
        </div>
      </template>
      <div class="lxp-modal__body">
        <img src="@/assets/images/vle/timer/assignment-late.svg" alt="info" />
        <h3 class="lxp-modal__title">
          {{ $t("vle.error.incomplete.title") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("vle.error.incomplete.message") }}
        </p>
      </div>

      <template #modal-footer>
        <div class="lxp-modal__footer">
          <LxpButton
            @click="closeModal(USER_LAYOUT_MODAL_IDS.UNFINISH_MODAL)"
            class="um__unfinish--button"
          >
            {{ $t("general.return") }}
          </LxpButton>
        </div>
      </template>
    </b-modal>
    <!-- close modal -->
    <b-modal
      :id="USER_LAYOUT_MODAL_IDS.CANCEL_MODAL"
      centered
      :modal-class="
        getTimedAssessmentFlag ? 'timer-assessment-modal' : 'cancel-modal'
      "
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <template #modal-header>
        <div
          class="close-icon--styles"
          @click="closeModal(USER_LAYOUT_MODAL_IDS.CANCEL_MODAL)"
        >
          <img src="@/assets/images/close.svg" alt="close" />
        </div>
      </template>
      <div class="sp__body p-0" v-if="getCurrentMenuItem != 'modules'">
        <div class="text-center">
          <img src="@/assets/images/vle/exit-modal.svg" />
        </div>
        <div class="sp__body--content text-center ">
          <div class="sp__enroll--alert-title">
            {{ $t("vle.modal.discussion.pause.heading") }}
          </div>
          <div class="cd-sec__des text-center ">
            <div class="sp_enroll--alert-message">
              {{ $t("vle.modal.discussion.pause.content") }}
            </div>
          </div>
        </div>
      </div>
      <div class="sp__body p-0" v-else-if="getTimedAssessmentFlag === true">
        <div class="text-center">
          <div class="image-container">
            <img
              src="@/assets/images/vle/timer/timed-orange.svg"
              alt="Timer"
              class="img-fluid timer-top"
            />
            <img
              src="@/assets/images/vle/timer/timed-info-orange.svg"
              alt="Timer"
              class="timer-bottom"
            />
          </div>
        </div>
        <div class="sp__body--content text-center ">
          <div class="sp__enroll--alert-title">
            {{ $t("vle.modal.timed_assessment.submit.heading") }}
          </div>
          <div class="cd-sec__des text-center ">
            <div class="sp_enroll--alert-message">
              {{ $t("vle.modal.timed_assessment.submit.content") }}
            </div>
          </div>
        </div>
      </div>
      <div class="sp__body p-0" v-else>
        <div class="text-center">
          <img src="@/assets/images/vle/exit-modal.svg" alt="exit" />
        </div>

        <div
          class="sp__body--content text-center"
          v-if="isLastSequence && isLastUnit"
        >
          <div class="sp__enroll--alert-title">
            {{ $t("vle.modal.finish.heading") }}
          </div>
          <div class="sp-sec__des text-center ">
            <div class="sp__enroll--alert-message">
              {{ $t("vle.modal.finish.content") }}
            </div>
          </div>
        </div>
        <div class="sp__body--content text-center" v-else>
          <div class="sp__enroll--alert-title">
            {{ $t("vle.modal.exit.heading") }}
          </div>
          <div class="sp-sec__des text-center ">
            <div class="sp__enroll--alert-message">
              {{ $t("vle.modal.exit.content") }}
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div
          class="w-100 d-flex justify-content-around ta-footer mb-3"
          v-if="getCurrentMenuItem !== 'modules'"
        >
          <Button
            variant="outline-warning"
            @click="closeModal(USER_LAYOUT_MODAL_IDS.CANCEL_MODAL)"
            pill
            >{{ $t("general.dismiss") }}</Button
          >
          <Button
            variant="warning"
            @click="goToCourseDetail"
            class="ml-3"
            pill
            >{{ $t("general.ok") }}</Button
          >
        </div>
        <div
          class="d-flex justify-content-around"
          v-else-if="getTimedAssessmentFlag === true"
        >
          <Button
            variant="outline-primary"
            @click="goToCourseDetail"
            class="continue-learning"
            >{{ $t("general.close") }}</Button
          >
          <Button
            variant="custom-primary-vle"
            @click="closeModal(USER_LAYOUT_MODAL_IDS.CANCEL_MODAL)"
            class="ml-3"
            pill
            >{{ $t("general.continue") }}</Button
          >
        </div>
        <div class="w-100" v-else>
          <div
            class="d-flex justify-content-around"
            v-if="isLastSequence && isLastUnit"
          >
            <Button variant="outline-danger" @click="goToCourseDetail">{{
              $t("course.exit_learning")
            }}</Button>
            <Button
              variant="primary"
              @click="closeModal(USER_LAYOUT_MODAL_IDS.CANCEL_MODAL)"
              class="ml-5"
              >{{ $t("course.continue_learning") }}</Button
            >
          </div>
          <div class="d-flex justify-content-around cm__exit-buttons" v-else>
            <Button
              variant="outline-danger"
              @click="goToCourseDetail"
              class="cm__exit-learning"
            >
              {{ $t("course.exit_learning") }}
            </Button>
            <Button
              variant="custom-primary-vle"
              @click="closeModal(USER_LAYOUT_MODAL_IDS.CANCEL_MODAL)"
              class="cm__continue-learning"
            >
              {{ $t("course.continue_learning") }}
            </Button>
          </div>
        </div>
      </template>
    </b-modal>
    <!-- walkthrough  modal -->
    <b-modal
      :id="USER_LAYOUT_MODAL_IDS.WALKTHROUGH_MODAL"
      centered
      size="xs"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      header-class="vle__wt-modal"
    >
      <template #modal-header="{}">
        <div class="d-flex justify-content-end w-100">
          <div
            class="close-icon-styles"
            @click="closeModal(USER_LAYOUT_MODAL_IDS.WALKTHROUGH_MODAL)"
          >
            <img src="@/assets/images/close.svg" />
          </div>
        </div>
      </template>
      <div class="cm__body--content text-center">
        <div class="cd-sec__des--title">
          {{ $t("vle.walkthrough.model.title") }}
        </div>
        <div class="cd-sec__des--message">
          {{ $t("vle.walkthrough.model.subtitle") }}
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 mb-3">
          <div class="d-flex justify-content-around">
            <Button
              variant="outline-primary"
              @click="closeModal(USER_LAYOUT_MODAL_IDS.WALKTHROUGH_MODAL)"
              className="wm__later-button"
            >
              {{ $t("vle.walkthrough.model.buttons.may_be_later") }}
            </Button>

            <Button
              className="wm__lets-begin-button"
              @click="
                closeModal(USER_LAYOUT_MODAL_IDS.WALKTHROUGH_MODAL),
                  $emit('startTour')
              "
            >
              {{ $t("vle.walkthrough.model.buttons.lets_begin") }}
            </Button>
          </div>
        </div>
      </template>
    </b-modal>
    <!-- Discussions forums modal -->
    <b-modal
      :id="USER_LAYOUT_MODAL_IDS.DISCUSSIONS_FORUMS_MODAL"
      centered
      modal-class="forums-modal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      hide-header
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div class="sp__body p-0" role="document">
        <img
          src="@/assets/images/close-white.svg"
          alt="close"
          class="close-icon--styles"
          @click="closeModal(USER_LAYOUT_MODAL_IDS.DISCUSSIONS_FORUMS_MODAL)"
          aria-label="Close"
          role="button"
          tabindex="0"
          @keydown.enter="
            closeModal(USER_LAYOUT_MODAL_IDS.DISCUSSIONS_FORUMS_MODAL)
          "
        />
        <div class="sp__body--content text-center">
          <div class="sp_banner">
            <img src="@/assets/images/vle/forums/banner.svg" alt="banner" />
          </div>
          <div class="sp__body--inner">
            <div class="sp__body--title" id="modal-title">
              {{ $t("discussions.modal.title") }}
            </div>
            <div class="sp__body--subtext">
              {{ $t("discussions.modal.content") }}
            </div>
          </div>
          <ul class="sp__features">
            <li>
              <span>
                <img
                  src="@/assets/images/vle/forums/features/search.svg"
                  alt="banner"
                />
              </span>
              <span class="sp__features--title">
                {{ $t("discussions.modal.features.search_posts.title") }}
              </span>
              <span class="sp__features--content">
                {{ $t("discussions.modal.features.search_posts.content") }}
              </span>
            </li>
            <li>
              <span>
                <img
                  src="@/assets/images/vle/forums/features/clapping.svg"
                  alt="banner"
                />
              </span>
              <span class="sp__features--title">
                {{ $t("discussions.modal.features.support_ideas.title") }}
              </span>
              <span class="sp__features--content">
                {{ $t("discussions.modal.features.support_ideas.content") }}
              </span>
            </li>
            <li>
              <span>
                <img
                  src="@/assets/images/vle/forums/features/report.svg"
                  alt="banner"
                />
              </span>
              <span class="sp__features--title">
                {{ $t("discussions.modal.features.report.title") }}
              </span>
              <span class="sp__features--content">
                {{ $t("discussions.modal.features.report.content") }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-around ta-footer mb-3">
          <LxpButton
            @click="closeModal(USER_LAYOUT_MODAL_IDS.DISCUSSIONS_FORUMS_MODAL)"
            class="um__unfinish--button"
            aria-label="Close and Explore"
            role="button"
          >
            {{ $t("discussions.modal.explore") }}
          </LxpButton>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";
import USER_LAYOUT_MODAL_IDS from "./constants";
import goToPage from "@/components/SelfPaced/mixins/userLayout";
export default {
  components: { LxpButton },
  mixins: [goToPage],
  computed: {
    ...mapGetters([
      "getTimedAssessmentFlag",
      "getCurrentMenuItem",
      "isLastSequence",
      "isLastUnit"
    ])
  },
  data() {
    return {
      USER_LAYOUT_MODAL_IDS: {}
    };
  },
  watch: {},
  mounted() {
    this.USER_LAYOUT_MODAL_IDS = USER_LAYOUT_MODAL_IDS;
  },
  methods: {
    closeModal(id) {
      this.$bvModal.hide(id);
    },
    goToCourseDetail() {
      // exit full screen before closing the exit modal
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      localStorage.setItem("isScormUnit", "0");
      this.$store.commit("SET_CURRENT_MENU_ITEM", "modules");
      this.goToPage(
        { id: this.$route.params.id },
        "Courses Detail",
        this.$route.query.from === "learning-programmes"
          ? { from: "learning-programmes" }
          : this.$route.query.from === "home-courses"
          ? { from: "home-courses" }
          : {}
      );
    }
  }
};
</script>
<style lang="scss">
.cm__exit-buttons {
  .cm__exit-learning {
    &:hover {
      background-color: $brand-neutral-0;
    }
  }
}
.wm__lets-begin-button {
  @include button-label;
  @include modal-button-size;
  width: 193px;
  gap: 8px;
  margin-right: 26px;
}

#cancel-modal {
  .modal-dialog {
    max-width: 583px;
  }
  .modal-header {
    border: none;
    padding: 12px;
  }
  .modal-footer {
    justify-content: center;
    border: none;
    padding-bottom: 24px;
    .btn {
      padding: 8px 32px;
      border-radius: 100px;
      text-transform: capitalize;
      @include dashboard-label;
      width: 225px;
      &.um__enrolled-dismiss {
        background: $brand-neutral-0;
        border: 1px solid $brand-primary;
        color: $brand-primary;
        &:hover {
          background: $brand-neutral-0 !important;
          color: $brand-primary !important;
        }
      }
    }
  }
  .modal-body {
    padding: 0 10px 0;
    .image-container {
      position: relative;
      width: 56px;
      height: 56px;
      @include flex-horizontal-center;
      margin: auto;
      margin-top: 20px;
    }
    .timer-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 97%;
      height: auto;
      background-color: #fff7e8;
      border-radius: 7px;
    }
    .timer-bottom {
      position: absolute;
      bottom: -10px;
      left: 27px;
      width: 67%;
      height: auto;
      background-color: $brand-primary-100;
      border-radius: 26px;
    }
    .cd-enroll__div {
      &.cd-main {
        padding: 0;
        .cd-main__sec {
          box-shadow: none;
          padding: 0;
        }
      }
      .cd-enroll__sec {
        padding: 5% 15%;
        h3 {
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.2px;
          color: #0043ac;
          margin-left: 5%;
          padding: 7% 12% 2%;
          font-weight: 500;
        }
      }
      .cd-enroll__message {
        @include body-regular;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        &.fw-500 {
          font-weight: 500;
        }
      }
    }
  }
  .sp__enroll--alert-title {
    @include subtitle-large;
    letter-spacing: 0.0025em;
    color: $brand-neutral-900;
    padding-top: 18px;
    padding-bottom: 8px;
  }
  .sp__enroll--alert-message {
    @include body-regular;
    margin-bottom: 16px;
    padding: 0 6px;
  }
  .close-icon--styles {
    right: 20px;
    top: 20px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    position: absolute;
    cursor: pointer;
    border-radius: 16px;
    z-index: 1;
  }
}
.cd-sec__des--title {
  @include subtitle-large;
  color: $brand-neutral-900;
}
.cd-sec__des--message {
  @include body-regular;
}
.forums-modal {
  .close-icon--styles {
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
  .sp_banner {
    width: 100%;
  }
  .sp__body--inner {
    margin: 24px 0px;
  }
  .sp__body--title {
    @include subtitle-large($brand-neutral-900);
  }
  .sp__body--subtext {
    color: $brand-neutral-700;
    letter-spacing: 0.25px;
    margin-top: 8px;
  }
  .sp__features {
    border-radius: 8px;
    background: $brand-primary-100;
    list-style-type: none;
    display: flex;
    padding: 16px 8px;
    margin: 0;
    li {
      display: flex;
      flex-direction: column;
      .sp__features--title {
        @include body-regular($brand-neutral-900, 500);
        margin-top: 4px;
      }
      .sp__features--content {
        @include label-medium;
        margin-top: 4px;
        color: $brand-neutral-700;
      }
    }
  }
  .ta-footer {
    margin-top: 0;
  }
}
</style>
