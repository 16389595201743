<template>
  <div ref="topOfPage">
    <div
      :class="{
        'sp__left-nav__module-wrapper sp__left-nav__module-wrapper--open': true,
        'sp__left-nav__teams-wrapper--no-header': getCurrentMenuItem === 'teams'
      }"
    >
      <div class="sp__left-modules" v-if="getCurrentMenuItem === 'modules'">
        <ModuleList
          v-for="(m, i) in modules"
          :key="i"
          :module="m"
          :isOpen="m.isOpen"
          :currentModuleNo="i + 1"
          :totalModule="modules.length"
          @module-selected="scrollToTop"
          @module-open="onModuleOpen"
        />
      </div>
      <div
        v-else-if="getCurrentMenuItem === 'forums'"
        class="sp__left-nav__forum-wrapper"
      >
        <DiscussionList @selectedCategory="$emit('selectedCategory', $event)" />
      </div>
      <div
        v-else-if="getCurrentMenuItem === 'teams'"
        class="sp__left-nav__team-wrapper"
      >
        <LeftNav :courseTitle="getSelectedCourse.title" />
      </div>
    </div>
  </div>
</template>
<script>
import ModuleList from "./ModuleList.vue";
import DiscussionList from "./Discussion/DiscussionList.vue";
import LeftNav from "../Teams/Shared/LeftNav.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModuleList,
    DiscussionList,
    LeftNav
  },
  computed: {
    ...mapGetters([
      "getSelectedCourse",
      "getCourseCompletionPercentage",
      "getCurrentMenuItem",
      "getTimedAssessmentFlag",
      "getCurrentSection"
    ]),
    modules() {
      let arr = [];
      if (this.getSelectedCourse.sections) {
        arr = this.getSelectedCourse.sections.map(obj => ({
          ...obj,
          isOpen: this.getCurrentSection.id === obj.id
        }));
      }
      return arr;
    }
  },
  watch: {
    getTimedAssessmentFlag(val) {
      if (val === true && this.isOpen) {
        this.toggleLeftNav();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.getCurrentMenuItem === "modules") {
        document.getElementById("ul-content__main").scrollTo({
          behavior: "smooth",
          top: 0,
          left: 0
        });
      }
    });
  },
  methods: {
    scrollToTop() {
      this.$store.commit("TOGGLE_VLE_SIDE_NAV");
      let elem = document.getElementById("app");
      elem.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    },
    toggleLeftNav() {
      if (this.getCurrentMenuItem === "modules") {
        this.$emit("click");
      }
    },
    onModuleOpen(index) {
      this.modules.forEach((m, i) => {
        m.isOpen = false;
        if (i === index) {
          m.isOpen = true;
        }
      });
      this.$forceUpdate();
    }
  }
};
</script>
<style lang="scss" scoped>
$toggle-section-height: 50px;
.sp__left-nav__forum-wrapper {
  text-align: left;
}
.sp__left-nav__team-wrapper {
  text-align: left;
  margin-left: 25px;
}
.sp__left-modules::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>
