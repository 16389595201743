<template>
  <div class="sp-confirmation__wrapper">
    <div class="sp-confirmation__container">
      <h3 class="sp-confirmation__title">
        {{ $t("my_achievements.awards.congratulations") }}
      </h3>
      <div class="sp-confirmation--div">
        <div class="sp-confirmation__card">
          <img :src="awardTypeLogo" class="spc-card__rewardimg" />
          <div class="spc-card__topwrapper">
            <button class="sp-confirmation__viewbtn" @click="viewCertificate">
              {{ $t("my_achievements.awards.view") }}
            </button>
            <h2 class="spc-card__title">{{ awardTypeTitle }}</h2>
            <h6 class="spc-card__title1">
              {{ $t("my_achievements.awards.presented_to") }}
            </h6>
            <h3 class="spc-card__username">
              {{ getCredentials.user.first_name }}
              {{ getCredentials.user.last_name }}
            </h3>
            <h6 class="spc-card__title2">
              {{ $t("my_achievements.awards.success_message") }}
            </h6>
          </div>
          <div class="spc-card__bottomwrapper">
            <div
              :class="['spc-card__share', { copied: isShareCopied }]"
              @click="shareAwards(getCredentials.share_url)"
            >
              <div v-if="!isShareCopied">
                <img
                  src="@/assets/images/achievements/detail/share-grey.svg"
                  class="spc-card__share--img"
                  width="24"
                  height="24"
                />
                <span>{{ $t("my_achievements.awards.share") }}</span>
              </div>
              <div class="spc-card__share--copied" v-else>
                <img
                  src="@/assets/images/achievements/detail/share-copied.svg"
                  alt="check"
                  width="20"
                  class="spc-card__share--img"
                />
                <span>{{
                  $t("my_achievements.download_or_share.copied")
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="sp-confirmation__milestones" v-if="milestones.length > 0">
          <h5>{{ $t("my_achievements.awards.next") }}</h5>
          <p>{{ $t("my_achievements.awards.content_unlocked_message") }}</p>
          <milestones />
        </div>
        <div class="sp-confirmation__btn" v-else>
          <h5 class="sp-confirmation__content">
            {{ $t("my_achievements.awards.course_unlocks_potential_message") }}
          </h5>
          <div class="sp-button__wrapper">
            <LxpButton class="sp-confirmation__button" @click="keepLearning()"
              >{{ $t("my_achievements.awards.keep_learning") }}
            </LxpButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LxpButton } from "didactica";
import { mapGetters } from "vuex";
// components
import Milestones from "./Awards/Milestones.vue";
// mixins
import downloadCopy from "../MyAchievements/mixins/downloadCopy";
export default {
  components: { LxpButton, Milestones },
  mixins: [downloadCopy],
  computed: {
    ...mapGetters(["getCredentials", "getSelectedCourse", "milestones"]),
    awardType() {
      return this.getCredentials?.content_type?.slug;
    },
    awardTypeLogo() {
      let logo;
      if (this.awardType === "course_completion_award") {
        logo = require("@/assets/images/achievements/list/award-of-completion.svg");
      } else if (this.awardType === "course_participation_confirmation") {
        logo = require("@/assets/images/achievements/list/confirmation-of-participation.svg");
      }
      return logo;
    },
    awardTypeTitle() {
      let text;
      if (this.awardType === "course_completion_award") {
        text = this.$t("my_achievements.award_of_completion");
      } else if (this.awardType === "course_participation_confirmation") {
        text = this.$t("my_achievements.confirmation_of_participation");
      }
      return text;
    }
  },
  created() {
    this.listMilestones();
  },
  methods: {
    viewCertificate() {
      this.$router.push({
        name: "Achievements Detail",
        params: { id: this.getCredentials.uuid }
      });
    },
    listMilestones() {
      this.$store.dispatch("listCourseMilestones", {
        courseId: this.getSelectedCourse.id
      });
    },
    keepLearning() {
      this.$router.push({ name: "course-discovery" });
    }
  }
};
</script>

<style lang="scss" scoped>
.sp-confirmation__wrapper {
  .sp-confirmation__container {
    @include center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;

    .sp-confirmation__title {
      @include subtitle-large($brand-neutral-900);
    }
    .sp-confirmation--div {
      width: 607px;
      .sp-confirmation__content {
        @include body-regular();
        margin: 24px 0;
      }
      .sp-confirmation__card {
        @include center;
        padding: 0px 16px 24px 16px;
        position: relative;
        gap: 24px;
        border-radius: 8px;
        background: $bg-light;
        box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);

        .spc-card__rewardimg {
          width: 38px;
        }
        .spc-card__topwrapper {
          @include center;
          gap: 8px;
          padding-bottom: 24px;
          border-bottom: 1px solid $brand-neutral-200;

          .sp-confirmation__viewbtn {
            padding: 4px 16px;
            gap: 8px;
            border-radius: 100px;
            border: 1px solid $brand-primary;
            position: absolute;
            right: 8px;
            top: 8px;
            background: transparent;
          }
          .spc-card__title {
            @include h5($brand-primary, 700);
            margin: 8px;
          }
          .spc-card__title1 {
            text-transform: uppercase;
          }
          .spc-card__title1,
          .spc-card__title2 {
            @include body-regular();
            margin: 0;
          }
          .spc-card__username {
            @include h4($brand-neutral-900);
            margin: 0;
          }
        }

        .spc-card__bottomwrapper {
          @include flex-horizontal-center;
          padding: 0px 16px;
          gap: 16px;
          .spc-card__share {
            @include label-large($brand-neutral-700, 500);
            margin: 0;
            padding: 4px 16px;
            cursor: pointer;
            div {
              display: flex;
              gap: 4px;
            }
            &:hover,
            &:focus,
            &:active,
            &.copied {
              border-radius: 100px;
              color: $brand-primary-400;
              background: $brand-neutral-0;
              img {
                filter: invert(50%) sepia(80%) saturate(6547%)
                  hue-rotate(199deg) brightness(107%) contrast(101%);
              }
            }
            &:focus {
              border: 1px solid $brand-primary-400;
            }
            .spc-card__share--copied {
              img {
                filter: none;
              }
              span {
                color: $brand-success-400;
              }
            }
          }
        }
      }

      .sp-button__wrapper {
        .sp-confirmation__button {
          width: 100%;
        }
      }
      .sp-confirmation__milestones {
        margin-top: 32px;
        h5 {
          @include subtitle-large($brand-neutral-900);
          margin-bottom: 8px;
        }
        p {
          @include body-regular();
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
