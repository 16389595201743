<template>
  <transition name="fade">
    <div
      :class="[
        'sp-header',
        {
          'sp-header__modules': getCurrentMenuItem === 'modules',
          'sp-header__scorm': isScormUnit && getCurrentMenuItem === 'modules',
          'left-panel__hide': !isVleSideNavOpen
        }
      ]"
    >
      <nav
        id="sidebar"
        :class="{
          active: !isToggleOpen,
          forums_sidebar: getCurrentMenuItem === 'forums'
        }"
      >
        <LeftNav @selectedCategory="$emit('selectedCategory', $event)" />
      </nav>
      <div
        class="sp-header__bg"
        id="sp-header__bg"
        v-if="getCurrentMenuItem === 'modules'"
        @click="$store.commit('TOGGLE_VLE_SIDE_NAV')"
      ></div>
    </div>
  </transition>
</template>
<script>
import LeftNav from "@/components/SelfPaced/LeftNav.vue";
import { mapGetters } from "vuex";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";
import TIMED_ASSESSMENT_MODAL_IDS from "./TimedAssessment/constants";
import timedAssessment from "./mixins/timedAssessment";
export default {
  mixins: [discussionViewSelectorMixin, timedAssessment],
  components: {
    LeftNav
  },
  props: {
    isToggleOpen: Boolean
  },
  data() {
    return {
      isOpen: false,
      TIMED_ASSESSMENT_MODAL_IDS: {}
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentMenuItem",
      "getTimedAssessmentFlag",
      "getGradedFlag",
      "getTimedAssessmentDuration",
      "getCurrentSequenceId",
      "isLastSequence",
      "isLastUnit",
      "language",
      "isScormUnit",
      "isVleSideNavOpen"
    ])
  },
  created() {
    this.TIMED_ASSESSMENT_MODAL_IDS = TIMED_ASSESSMENT_MODAL_IDS;
  },
  mounted() {
    if (this.getCurrentMenuItem === "teams") {
      this.isOpen = true;
    } else if (this.getCurrentMenuItem === "modules") {
      setTimeout(() => {
        let bgElem = document.getElementById("sp-header__bg"),
          sidebarHeight = document.getElementById("sidebar").scrollHeight;
        if (bgElem.scrollHeight < sidebarHeight) {
          bgElem.style.height = `${sidebarHeight + 20}px`;
        }
      }, 500);
    }
  },
  methods: {
    toggleLeftNav() {
      this.isOpen = !this.isOpen;
      this.$emit("onNavClick", this.isOpen);
    },
    goToCourseDetail() {
      this.$emit("goToCourseDetail");
    },
    showAddPostForm() {
      this.$store.commit(
        "SET_DISCUSSION_VIEW_SELECTOR",
        this.DISCUSSION_VIEW_SELECTOR.AddPostForm
      );
    }
  }
};
</script>

<style lang="scss" scoped>
$position-in-px: 150px;
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sp-header {
  &.sp-header__modules {
    width: 100%;
    height: 100%;
    position: absolute;
    &.sp-header__scorm {
      overflow: hidden auto;
    }
    #sidebar {
      z-index: 2;
      position: relative;
      max-width: 375px;
      .active {
        margin-left: -340px;
      }
    }
    .sp-header__bg {
      width: 100%;
      height: 100%;
      background: rgba(45, 45, 45, 0.5);
      filter: blur(4px);
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }
  &.left-panel__hide {
    display: none;
  }

  #sidebar {
    min-width: 340px;
    max-width: 340px;
    padding-top: 27px;
    color: #fff;
    transition: all 0.3s;
    &.forums_sidebar {
      padding-top: 0;
      min-width: 312px;
      max-width: 312px;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  .sp-header {
    #sidebar {
      margin-left: -340px;
    }
    .active {
      margin-left: 0px;
    }
  }
  #sidebarCollapse {
    .span {
      display: none;
    }
  }
}
[dir="rtl"] {
  #sidebar.active {
    margin-right: -340px;
  }
}
</style>
