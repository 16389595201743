<template>
  <div>
    <!-- unenroll modal -->
    <b-modal
      :id="MODAL_IDS.UNENROLL_MODAL"
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <template #modal-header>
        <div class="lxp-modal__header">
          <div
            class="close-icon-styles"
            @click="closeModal(MODAL_IDS.UNENROLL_MODAL)"
          >
            <img src="@/assets/images/close.svg" alt="close" />
          </div>
        </div>
      </template>
      <div class="lxp-modal__body">
        <img
          src="@/assets/images/vle/information.svg"
          class="um__info--image"
          alt="information"
          width="40"
          height="40"
        />
        <h3 class="lxp-modal__title ">
          {{ $t("course.unenroll.alert.title") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("course.unenroll.alert.message") }}
        </p>
      </div>

      <template #modal-footer>
        <div class="lxp-modal__footer">
          <LxpButton
            @click="unenroll"
            variant="warning"
            class="um__unenroll--button"
          >
            {{ $t("course.unenroll.buttons.unenroll") }}
          </LxpButton>
          <LxpButton
            variant="outline-primary"
            @click="closeModal(MODAL_IDS.UNENROLL_MODAL)"
            class="um__dismiss--button"
          >
            {{ $t("course.unenroll.buttons.dismiss") }}
          </LxpButton>
        </div>
      </template>
    </b-modal>
    <!--Unenroll success modal-->
    <b-modal
      :id="MODAL_IDS.UNENROLL_SUCCESS_MODAL"
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      class="um__success--modal"
    >
      <template #modal-header>
        <div class="lxp-modal__header">
          <div
            class="close-icon-styles"
            @click="unenrollSuccessModalClose(MODAL_IDS.UNENROLL_SUCCESS_MODAL)"
          >
            <img src="@/assets/images/close.svg" alt="close" />
          </div>
        </div>
      </template>
      <div class="lxp-modal__body">
        <img
          src="@/assets/images/vle/checkmark.svg"
          alt="chechmark"
          width="34"
          height="23"
        />
        <h3 class="lxp-modal__title">
          {{ $t("course.unenroll.unenrolled_alert.title") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("course.unenroll.unenrolled_alert.message") }}
        </p>
      </div>

      <template #modal-footer>
        <div class="lxp-modal__footer">
          <LxpButton
            @click="unenrollSuccessModalClose(MODAL_IDS.UNENROLL_SUCCESS_MODAL)"
            class="cm__discovering--button"
          >
            {{ $t("course.unenroll.unenrolled_alert.keep_discovering_button") }}
          </LxpButton>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";
import MODAL_IDS from "../../WrapperComponents/Modals/constants";
export default {
  props: {
    courseId: {
      type: String
    }
  },
  components: { LxpButton },
  data() {
    return {
      MODAL_IDS: {}
    };
  },
  computed: {
    ...mapGetters(["allConfig"])
  },
  mounted() {
    this.MODAL_IDS = MODAL_IDS;
  },
  methods: {
    closeModal(id) {
      this.$bvModal.hide(id);
    },
    unenroll() {
      axios
        .post(
          `${this.allConfig.baseURL}/enrollments/v2/courses/${this.courseId}/unenroll/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`
            }
          }
        )
        .then(res => {
          if (res.status === 200) {
            this.$bvModal.hide(this.MODAL_IDS.UNENROLL_MODAL);
            this.$bvModal.show(this.MODAL_IDS.UNENROLL_SUCCESS_MODAL);
          }
        })
        .catch(() => {
          this.$bvModal.hide(this.MODAL_IDS.UNENROLL_MODAL);
        });
    },
    unenrollSuccessModalClose(id) {
      this.$bvModal.hide(id);
      this.$router.push({ name: "course-discovery" }).catch(() => {});
      this.$emit("unenrollSuccessModalClose");
    }
  }
};
</script>
