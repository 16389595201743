const USER_LAYOUT_MODAL_IDS = {
  UNENROLL_MODAL: "unenroll-modal",
  UNENROLL_SUCCESS_MODAL: "unenroll-success-modal",
  MICROCREDENTIAL_MODAL: "microcredential-modal",
  UNFINISH_MODAL: "unfinish-modal",
  CANCEL_MODAL: "cancel-modal",
  WALKTHROUGH_MODAL: "walkthrough-modal",
  DISCUSSIONS_FORUMS_MODAL: "discussions-forums-modal"
};

export default USER_LAYOUT_MODAL_IDS;
