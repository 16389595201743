<template>
  <div class="module-list__wrapper">
    <vle-accordion :open="open" @toggle="onToggle" :module="module">
      <template v-slot:accordion-title>
        <div
          class="module-list__header"
          :class="{ 'module-list__header--complete': module.complete }"
        >
          <div class="module-list__header-title">
            {{ module.title }}
          </div>
          <span class="module-list__header-sub-title"
            >{{ currentModuleNo }} / {{ totalModule }}</span
          >
        </div>
      </template>
      <template v-slot:accordion-body>
        <div
          v-for="(sequence, index) in module.sequences"
          :key="index"
          class="module-list__content"
        >
          <div class="module-list__sub-header">
            <div class="module-list__sub-header-title">
              {{ sequence.title }}
            </div>
          </div>

          <div class="module-list__container">
            <ul>
              <li
                v-for="(unit, index) in sequence.units"
                :key="index"
                class="module-list__section c-pointer"
                :class="{
                  'module-list__section--active': getCurrentUnitId === unit.id,
                  'module-list__section--completed': unit.complete === true,
                  'module-list__section--locked':
                    isPrerequisiteCompleted(sequence) !== true,
                  'module-list__section--only-child': sequence.units.length == 1
                }"
                @click="selectModule(sequence, unit.id)"
                :id="`module-list__${unit.id}-${index}`"
              >
                <div class="d-flex">
                  <div class="sp__module-align align-self-center">
                    <img
                      v-if="isPrerequisiteCompleted(sequence) !== true"
                      :src="
                        require('@/assets/images/module-list/unit-locked.svg')
                      "
                      alt="unit locked"
                    />
                    <img
                      v-else-if="isUnitCompleted(unit)"
                      :src="
                        require('@/assets/images/module-list/unit-complete.svg')
                      "
                      alt="unit complete"
                    />
                    <img
                      v-else-if="getCurrentUnitId === unit.id"
                      :src="
                        allProfile.profile_image_url
                          ? allProfile.profile_image_url
                          : require('@/assets/images/module-list/avatar.svg')
                      "
                      alt="avatar"
                      width="24px"
                      height="24px"
                      class="rounded-circle"
                      id="profile-pic"
                    />
                    <img
                      v-else
                      :src="
                        getUnitIcon(unit.type, getCurrentUnitId === unit.id)
                      "
                      :alt="unit.type"
                    />
                  </div>
                  <div class="sp__module-align--title">
                    <p>{{ unit.title }}</p>
                    <span>{{ getUnitType(unit.type) }}</span>
                  </div>
                </div>
                <b-tooltip
                  :target="`module-list__${unit.id}-${index}`"
                  triggers="hover focus"
                  placement="top"
                  v-if="isPrerequisiteCompleted(sequence) === false"
                >
                  <div class="ml-cd__tooltip--inner">
                    <p class="mb-0">
                      {{ $t("course.modules.list") }}
                    </p>
                  </div>
                </b-tooltip>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-slot:[dynamicSlotName] v-if="module.complete === true">
        <div
          :class="[
            'module-list__completed--div',
            { 'module-list__container--open': open }
          ]"
        >
          <p class="lx-section-completed">
            {{ $t("my_learning.tab.completed") }}
          </p>
          <img
            src="@/assets/images/course-details/check-circle-fill.svg"
            alt="completed"
          />
        </div>
      </template>
    </vle-accordion>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VleAccordion from "@/components/WrapperComponents/VleAccordion.vue";
import userLayout from "./mixins/userLayout";
export default {
  mixins: [userLayout],
  props: {
    module: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    currentModuleNo: {
      type: Number,
      required: true
    },
    totalModule: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  components: {
    VleAccordion
  },
  computed: {
    ...mapGetters([
      "getCurrentUnitId",
      "isPrerequisiteCompleted",
      "getCurrentSection",
      "allProfile"
    ]),
    milestones() {
      let milestones = [];
      milestones.push({
        type: "major",
        isLocked: false,
        isCompleted: this.module.complete
      });
      this.module.sequences.forEach(sequence => {
        milestones.push({
          type: "minor",
          isLocked:
            this.isPrerequisiteCompleted(sequence) === true ? false : true,
          isCompleted: sequence.complete
        });
      });

      return milestones;
    },
    navIconPath() {
      let str = "";
      if (this.module.complete) {
        str = require("@/assets/images/vle/nav/completed.svg");
      } else if (
        this.module.sequences.findIndex(a => a.prerequisite !== null) !== -1 &&
        this.isSectionLocked()
      ) {
        str = require("@/assets/images/vle/nav/lock.svg");
      } else {
        str = require("@/assets/images/vle/nav/inprogress.svg");
      }
      return str;
    },
    dynamicSlotName() {
      return this.open
        ? "accordion-complete-no-collapse-icon"
        : "accordion-complete-collapse-icon";
    },
    isUnitCompleted() {
      return unit => {
        return (
          unit.complete &&
          !["non_mandatory_survey", "non_mandatory_poll"].includes(unit.type)
        );
      };
    }
  },
  watch: {
    isOpen(v) {
      this.open = v;
    }
  },
  mounted() {
    this.open = this.isOpen;
  },
  methods: {
    selectModule(module, unitId) {
      if (this.isPrerequisiteCompleted(module) === true && this.open) {
        // update the current section for micro-credential check
        this.$store.commit("SET_MODULE_TO_CHECK_MC", this.getCurrentSection);
        this.$store.commit("SET_CURRENT_UNIT_ID", unitId);
        this.$store.commit("SET_CURRENT_SEQUENCE_ID", module.id);
        this.$store.commit("SET_HEADER_NAV", true);
        this.$nextTick(() => {
          this.$emit("module-selected");
        });
      }
    },
    onToggle(bool) {
      this.open = bool;
      if (bool) {
        this.$emit("module-open", this.currentModuleNo - 1);
      }
    },
    isSectionLocked() {
      let bool = false;
      this.module.sequences.forEach(a => {
        if (this.isPrerequisiteCompleted(a) !== true) {
          bool = true;
        }
      });
      return bool;
    }
  }
};
</script>

<style scoped lang="scss">
.module-list__wrapper {
  margin: 0 30px 20px;
  width: 375px;
  .module-list__container {
    text-align: left;
    position: relative;
    ul {
      padding: 12px 0px 0;
      list-style: none;
      margin: 0;
      .module-list__section {
        color: $brand-secondary-text;
        @include body-medium;
        border-radius: 8px;
        padding: 4px 8px 4px 10px;
        margin: 5px 0 19px;
        &:hover {
          background: $brand-primary-100;
        }
        .sp__module-align {
          margin-right: 10px;
          img {
            width: 24px;
            max-height: 24px;
          }
        }
        .sp__module-align--title {
          p {
            @include label-large;
            margin-bottom: 0;
          }
          span {
            @include label-medium;
            color: $brand-neutral-300;
            @include line-clamp;
            padding-right: 16px;
          }
        }

        &.module-list__section--active {
          font-weight: 700;
          background: $brand-primary;
          color: $brand-primary-50;
          .sp__module-align {
            border: 3px solid rgb(0 129 255 / 50%);
            border-radius: 100px;
            img {
              border: 2px solid $brand-primary-400;
              border-radius: 100px;
            }
          }
          .sp__module-align--title {
            p {
              color: $brand-primary-50;
            }
          }
        }
        &.module-list__section--completed {
          span {
            background: none;
          }
        }
        &.module-list__section--locked {
          color: $brand-disabled-grey;
          cursor: not-allowed;
          &:hover {
            background: $brand-neutral-75;
            cursor: not-allowed;
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .module-list__title {
      @include button-label;
      color: $vle-primary-text;
      padding: 0 15px 0 10px;
      @include line-clamp;
    }
  }
  .module-list__header {
    &.module-list__header--complete {
      img {
        width: 14px;
        margin-left: 2px;
      }
    }
    img {
      margin-left: 1px;
      width: 16px;
      height: 16px;
    }
    .b-icon {
      font-size: 16px;
    }
    .module-list__header-title {
      @include subtitle-regular;
      text-align: left;
      color: $brand-neutral-900;
    }
    .module-list__header-sub-title {
      @include label-medium;
      text-align: left;
      color: $brand-neutral-300;
      display: flex;
      margin-top: 4px;
    }
  }
  .module-list__content {
    margin-top: 16px;
    border-bottom: 1px solid $brand-neutral-200;
    .module-list__sub-header-title {
      @include label-large;
      font-weight: 500;
      text-align: left;
      color: $brand-neutral-700;
    }
  }
  .module-list__completed--div {
    @include horizontal-space-between;
    margin: 10px 0 6px;
    .lx-section-completed {
      @include label-medium(500);
      color: $brand-success-400;
      margin-bottom: 0;
    }
    &.module-list__container--open {
      margin: 16px 0 0;
    }
  }
}

[dir="rtl"] {
  .module-list__wrapper {
    .module-list__container {
      .module-list__section {
        text-align: right;
        .sp__module-align {
          margin-right: 8px;
          padding-left: 10px;
        }
        span {
          padding-right: 0;
        }
      }
      .module-list__type {
        margin-right: 10px;
      }
    }
    .module-list__header-div {
      text-align: right;
      padding: 0 1rem 0 0;
    }
    .module-list__header-title {
      text-align: right;
    }
    .module-list__header-sub-title {
      text-align: right;
    }
    .module-list__sub-header-title {
      text-align: right;
    }
    .lx-section-completed {
      padding-right: 0px;
    }
  }
}
</style>
<style>
.ml-cd__tooltip--inner.cd__tooltip--inner {
  color: #fff;
}
</style>
