<template>
  <div class="vle-mobile__header d-show__mobile">
    <b-button v-b-toggle.mysidebar class="btn-menu">
      <img src="@/assets/images/vle/vle-menu.svg" class="c-pointer" />
    </b-button>
    <b-sidebar id="mysidebar" shadow v-model="showSidebar">
      <div class="exit-selfPaced" v-b-modal.cancel-modal>
        <img src="@/assets/images/vle/exit-mobile.svg" class="c-pointer" />
        <span class="px-2">{{ $t("vle.exit_vle") }}</span>
      </div>
      <div class="px-3 mt-4 text-start">
        <h6 class="px-3 mb-3 text-uppercase">
          {{ $t("vle.menu.currently") }}
        </h6>
        <span
          ><b-avatar :src="profileImageURL"></b-avatar>
          {{ this.$t("course.tab.learning") }}
        </span>
        <hr class="my-4" />
        <h6 class="px-3 mb-3 text-uppercase">
          {{ $t("discussions.modal.explore") }}
        </h6>
        <ul class="vle-list__menu pl-0">
          <li @click="selectMenuItem">
            <span class="list-icon rounded-circle">
              <img
                :src="
                  getCurrentMenuItem === 'forums'
                    ? require('@/assets/images/vle/learning-active.svg')
                    : require('@/assets/images/vle/forums.svg')
                "
                width="24"
              />
            </span>
            {{
              this.getCurrentMenuItem === "forums"
                ? this.$t("course.tab.learning")
                : this.$t("course.footer.forums")
            }}
          </li>
          <li @click="goToCouresData">
            <span class="list-icon rounded-circle">
              <img
                src="@/assets/images/vle/vle-course-information.svg"
                alt="information"
              />
            </span>
            {{ $t("vle.menu.course_information") }}
          </li>
          <li @click="startTour">
            <span class="list-icon rounded-circle">
              <img
                src="@/assets/images/vle/vle-walkthrough.svg"
                alt="walkthrough"
              />
            </span>
            {{ $t("vle.vle_menu.walkthrough") }}
          </li>
          <li @click="openFeedbackForm">
            <span class="list-icon rounded-circle">
              <img src="@/assets/images/vle/vle-feedback.svg" alt="feedback" />
            </span>
            {{ $t("my_profile.feedback") }}
          </li>
          <li v-b-modal.unenroll-modal v-if="unEnroll">
            <span class="list-icon rounded-circle">
              <img
                src="@/assets/images/icons/unenroll-module.svg"
                alt="unenroll"
              />
            </span>
            {{ $t("course.unenroll.title") }}
          </li>
        </ul>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//Mixins
import openFeedbackForm from "@/core/mixins/openFeedbackForm.js";
import goToCouresData from "@/components/SelfPaced/mixins/userLayout";
import courseCompletion from "@/components/SelfPaced/mixins/courseCompletion.js";
export default {
  mixins: [openFeedbackForm, goToCouresData, courseCompletion],
  props: {
    unEnroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showSidebar: false
    };
  },
  computed: {
    ...mapGetters(["getCurrentMenuItem", "getAuthProfile"]),
    profileImageURL() {
      return this.getAuthProfile.profile_image_url
        ? this.getAuthProfile.profile_image_url
        : require("@/assets/images/layout/user-fluid-layout/profile.svg");
    }
  },
  methods: {
    startTour() {
      this.showSidebar = false;
      this.$emit("walkThorugh");
    },
    selectMenuItem() {
      this.showSidebar = false;
      if (this.getCurrentMenuItem === "forums") {
        this.$store.commit("SET_CURRENT_MENU_ITEM", "modules");
      } else {
        this.$store.commit("SET_CURRENT_MENU_ITEM", "forums");
      }
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .vle-mobile__header {
    .b-sidebar {
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      h6 {
        @include settings-label;
        font-weight: 500;
        letter-spacing: 1.25px;
      }
      hr {
        border-color: $brand-neutral-200;
      }
      .close {
        font-size: 1.8rem;
        background: $brand-neutral-50;
        color: $brand-neutral-700 !important;
        opacity: 1;
        cursor: pointer;
        margin-left: 0px !important;
        padding: 8px;
        border-radius: 8px;
      }
      &.bg-light {
        background: $brand-neutral-0;
      }
      &.shadow {
        box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25) !important;
      }
      &.text-dark {
        color: $brand-neutral-900 !important;
      }
      .badge-secondary {
        background: none;
      }
      .exit-selfPaced {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }
      .b-avatar {
        margin-right: 8px;
      }
    }
    .btn-menu {
      overflow-anchor: none;
      background: $brand-neutral-50;
      border: 0px;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      padding: 8px;
      &:hover {
        background: $brand-neutral-50;
      }
    }
    .vle-list__menu {
      list-style: none;
      li {
        margin-bottom: 24px;
        cursor: pointer;
        .list-icon {
          background-color: $brand-primary-100;
          padding: 10px 8px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
