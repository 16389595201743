<template>
  <LeftNavDiscussion
    :isLoadingFlag="isLoading"
    @navClicked="getListOfPosts()"
    @selectedCategory="$emit('selectedCategory', $event)"
  />
</template>

<script>
import LeftNavDiscussion from "./LeftNavDiscussion.vue";
import { mapGetters } from "vuex";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";

export default {
  mixins: [discussionViewSelectorMixin],
  components: { LeftNavDiscussion },
  data() {
    return {
      isLoading: null
    };
  },
  mounted() {
    if (this.$route.params.topic_id) {
      this.isLoading = false;
      this.$store.commit("SET_TOPIC_ID", this.$route.params.topic_id);
    } else {
      this.isLoading = true;
      this.$store.commit("SET_TOPIC_ID", "");
    }

    this.$store.commit(
      "SET_DISCUSSION_VIEW_SELECTOR",
      this.DISCUSSION_VIEW_SELECTOR.PostsList
    );

    this.$store
      .dispatch("getDiscussionTopics", this.$route.params.id)
      .then(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapGetters(["getTopicId", "getDiscussionBreadcrumb", "language"])
  }
};
</script>
