<template>
  <div class="sp-landing__wrapper">
    <div class="sp-landing__container">
      <div class="sp-landing__row">
        <img
          src="@/assets/images/vle/timer/assessment-timer.svg"
          alt="Timer"
          class="timer-top"
          width="64"
          height="64"
        />
        <h4 class="sp-landing__heading">
          {{ $t("vle.timed_assessment.title") }}
        </h4>
        <p class="sp-landing__text">
          {{ $t("vle.assessment.timed.limit_message", { count: duration }) }}
        </p>
        <div class="sp-landing__btns--div">
          <LxpButton
            class="sp-landing__button text-uppercase"
            @click="startAssessment"
          >
            {{ $t("vle.timed_assessment.start") }}
          </LxpButton>
        </div>
      </div>
      <div class="landing__container_row">
        <div class="sp-landing__note-row">
          <img
            src="@/assets/images/vle/timer/timer-info.svg"
            alt="info"
            width="26"
            height="26"
          />
          <div class="sp-landing__note">
            <div class="sp-landing__note--alert">
              <h6 class="sp-landing__note-heading">
                {{ $t("vle.timed_assessment.info.title") }}
              </h6>
              <p class="sp-landing__note-text">
                {{ $t("vle.timed_assessment.info.message") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";
import nextUnit from "@/components/SelfPaced/mixins/nextUnit.js";
export default {
  mixins: [nextUnit],
  components: { LxpButton },
  computed: {
    ...mapGetters(["getCurrentSequenceId", "getTimedData"]),
    duration: function() {
      return parseInt(this.getTimedData.duration / 60, 10);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.commit("SET_IFRAME_CONTENT_STATUS", false);
    });
  },
  methods: {
    startAssessment() {
      const params = {
        courseId: this.$route.params.id,
        sequenceId: this.getCurrentSequenceId,
        state: "started"
      };
      this.$store.dispatch("updateTimedAssessment", params);
    }
  }
};
</script>

<style lang="scss" scoped>
.sp-landing__wrapper {
  height: 100%;
  z-index: 11 !important;
  .sp-landing__container {
    height: 100%;
    .sp-landing__row {
      background: #ffffff;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      border-radius: 8px;
      padding: 16px 16px 24px;
      @include center;
      .sp-landing__heading {
        @include subtitle-large($vle-primary-text);
        letter-spacing: 0.2px;
        margin: 25px 0 8px;
      }
      .sp-landing__text {
        @include body-regular;
        text-align: center;
        margin-bottom: 24px;
      }
    }
    .landing__container_row {
      .sp-landing__note-row {
        margin-top: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        img {
          margin-top: 5px;
        }
        .sp-landing__note {
          flex-grow: 1;
          box-sizing: border-box;
          border-radius: 10px;
          padding-left: 18px;
          .sp-landing__note--alert {
            text-align: left;
            .sp-landing__note-heading {
              @include subtitle-regular;
              color: $brand-neutral-700;
              margin-bottom: 0;
            }
            .sp-landing__note-text {
              @include label-large($brand-neutral-700);
              letter-spacing: 0.25px;
            }
          }
        }
      }
    }
  }
}
</style>
