var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour-style h-100"},[(_vm.isPageLoading)?_c('LxpLoader',{staticClass:"h-100"}):_c('UserLayout',{ref:"userLayout",attrs:{"layout-type":2},on:{"startTour":_vm.startTour,"handleCourseCompletion":_vm.handleCourseCompletion},scopedSlots:_vm._u([{key:"bottom-section",fn:function(){return [(_vm.isLoading)?_c('Loader'):_c('div',{class:[
          'h-100 vle-wrapper',
          _vm.getCurrentMenuItem,
          {
            'vle__overlay-fixed':
              _vm.getCurrentMenuItem != 'modules' && _vm.isNavOpen,
            'vle__sidenav--open':
              _vm.isVleSideNavOpen && _vm.getCurrentMenuItem !== 'modules',
            'vle-wrapper__scorm':
              _vm.isScormUnit && _vm.getCurrentMenuItem === 'modules'
          }
        ],attrs:{"id":"qa-self-paced-id"}},[_c('div',{staticClass:"sp__content-wrapper",class:{
            vle__success: _vm.isCourseCompleted,
            vle_forums: _vm.getCurrentMenuItem === 'forums'
          }},[_c('Header',{on:{"goToCourseDetail":_vm.goToCourseDetail,"onNavClick":_vm.onNavClick,"selectedCategory":_vm.handleCategoryName}}),(_vm.getCurrentMenuItem === 'forums')?_c('DiscussionWrapper',{attrs:{"selectedCategoryName":_vm.selectedCategoryName}}):_c('div',{staticClass:"sp__container-wrapper",class:{ 'd-flex': _vm.getCurrentMenuItem !== 'modules' }},[(!_vm.isScormUnit)?_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light align-self-baseline"},[_c('div',{staticClass:"container-fluid p-0"},[(!_vm.isCourseCompleted)?_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[(
                      _vm.getCurrentMenuItem === 'modules' &&
                        !_vm.getTimedAssessmentFlag
                    )?_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb sp__breadcrumb-content"},[_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(_vm.getCurrentSection.title)+" ")]),_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(_vm.getCurrentSequence.title)+" ")]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(_vm.getCurrentUnit ? _vm.getCurrentUnit.title : "")+" ")])])]):_vm._e()]):_vm._e()])]):_vm._e(),(!_vm.isCourseCompleted)?_c('div',{staticClass:"container-fluid sp__content-data"},[_c('div',{class:_vm.containerClassName,style:(_vm.isOpen && _vm.getCurrentMenuItem === 'modules'
                    ? 'width:90%'
                    : _vm.getTimedAssessmentFlag
                    ? ''
                    : '')},[_c('MainView',{attrs:{"isMainViewLoading":_vm.isMainViewLoading,"isOpen":_vm.isOpen},on:{"onFinish":_vm.handleOnFinish,"mainViewLoading":_vm.mainViewLoading,"handleCourseCompletion":_vm.handleCourseCompletion}})],1),_c('br')]):_c('div',{staticClass:"container-fluid sp__success-message"},[_c('Awards')],1)])],1),_c('unenroll-modal',{attrs:{"courseId":_vm.getSelectedCourse.id}})],1)]},proxy:true}])}),_c('VueTour',{attrs:{"steps":_vm.steps,"myCallbacks":_vm.myCallbacks},on:{"bulletClick":_vm.myCustomNextStepCallback,"repeatTour":_vm.repeatTour,"closeTour":_vm.closeTour}}),_c('Toast',{attrs:{"id":"my-toast","title":_vm.$t('vle.success.title'),"imgPath":require('@/assets/images/course-details/award.svg'),"description":_vm.toastDescription}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }