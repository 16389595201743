export default {
  data() {
    return {
      myCallbacks: {
        onSkip: this.onSkip,
        onNextStep: this.myCustomNextStepCallback,
        onStop: this.onStop
      }
    };
  },
  methods: {
    toggleZindexForDropDown(value) {
      let walkthrowDropdown = document.getElementById("expandWalkthrough");
      walkthrowDropdown.style.zIndex = value;
    },
    myCustomNextStepCallback(currentStep) {
      if (
        (this.getCourseViewSelector === 1 && currentStep === 2) ||
        (this.getCourseViewSelector === 3 && currentStep === 3) ||
        (this.isScormUnit && currentStep === 3) ||
        (this.getCurrentMenuItem !== "modules" && currentStep === 2) ||
        currentStep === 4
      ) {
        this.$refs["userLayout"].$refs["walkthrowDropdown"].show(true);
        this.$refs["userLayout"].$refs["expandWalkthrough"].classList.add(
          "ul-header__tour"
        );
        let walkthrowDropdown = document.getElementById("walkthrowDropdown");
        this.toggleZindexForDropDown(999999999999999);
        walkthrowDropdown.getElementsByClassName(
          "dropdown-menu"
        )[0].style.zIndex = "999999999999999";
        walkthrowDropdown.getElementsByClassName(
          "dropdown-menu"
        )[0].style.boxShadow = "none";
      } else {
        this.toggleZindexForDropDown(1);
        this.$refs["userLayout"].$refs["expandWalkthrough"].classList.remove(
          "ul-header__tour"
        );
      }
    },
    onSkip() {
      this.toggleZindexForDropDown(1);
      this.$store.commit("SET_VUE_TOUR_STATUS", false);
    },
    onStop() {
      this.onSkip();
    },
    repeatTour() {
      this.toggleZindexForDropDown(1);
    },
    closeTour() {
      this.toggleZindexForDropDown(1);
      this.$store.commit("SET_VUE_TOUR_STATUS", false);
    },
    startTour() {
      this.$tours["myTour"].start();
      this.$store.commit("SET_VUE_TOUR_STATUS", true);
      this.toggleZindexForDropDown(1);
    },
    repositionTour() {
      // if tour is running
      if (this.$tours["myTour"].isRunning) {
        this.$tours["myTour"].finish();
        setTimeout(() => {
          this.$tours["myTour"].start();
        }, "250");
      }
    }
  }
};
