<template>
  <div :class="[timerContainerClass, `sp-timer__${state}`]">
    <h5 class="sp-timer__text">
      <div width="24" height="24"></div>
      {{ durationString }}
    </h5>
    <div class="loader" :style="loaderStyle"></div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const FIRST_STAGE_PERCENTAGE = 20;
const SECOND_STAGE_PERCENTAGE = 5;
export default {
  props: {
    duration: Number,
    state: String
  },
  computed: {
    ...mapGetters([
      "getCurrentSequenceId",
      "isTimedAssessmentSubmitted",
      "iframeContentStatus"
    ]),
    firstStage() {
      return this.durationVal - 1 <=
        (FIRST_STAGE_PERCENTAGE * this.duration) / 100
        ? true
        : false;
    },
    secondStage() {
      return this.durationVal - 1 <=
        (SECOND_STAGE_PERCENTAGE * this.duration) / 100
        ? true
        : false;
    },
    durationString() {
      var minutes = parseInt(this.durationVal / 60, 10);
      var seconds = parseInt(this.durationVal % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return minutes + ":" + seconds;
    },
    loaderStyle() {
      let loaderWidth,
        bgColor = this.timerColorCode;
      if (this.state === "not_started") {
        loaderWidth = "5%";
        bgColor = "#A0A0A0";
      } else {
        loaderWidth = `${(this.durationVal / this.duration) * 100}%`;
      }
      return {
        width: loaderWidth,
        backgroundColor: bgColor
      };
    }
  },
  watch: {
    state: function(newVal) {
      this.initTimer(newVal);
    },
    isTimedAssessmentSubmitted(val) {
      if (val === true && this.timer) {
        clearInterval(this.timer);
      }
    },
    iframeContentStatus(val) {
      if (val === true && this.state === "started") {
        this.initTimer(this.state);
      }
    }
  },
  mounted() {
    this.durationVal = this.duration;
    this.initTimer(this.state);
  },
  data() {
    return {
      durationVal: 0,
      timerContainerClass: "sp-timer__container green",
      timerColorCode: "#0081ff",
      timer: null
    };
  },
  methods: {
    initTimer(val) {
      if (val === "started" && this.iframeContentStatus) {
        this.startTimer();
      }
    },
    submitAssessment() {
      this.$emit("onFinish");
    },
    startTimer() {
      this.timerContainerClass = "sp-timer__container green";
      this.timerColorCode = "#0081ff";
      this.timer = setInterval(
        function() {
          if (this.durationVal > 0) {
            if (this.secondStage) {
              this.timerContainerClass = "sp-timer__container red";
              this.timerColorCode = "#FF4040";
            } else if (this.firstStage) {
              this.timerContainerClass = "sp-timer__container orange";
              this.timerColorCode = "#ffaa15";
            }
            --this.durationVal;
          } else if (this.durationVal === 0) {
            this.timerContainerClass = "sp-timer__container red";
            this.timerColorCode = "#FF4040";
            if (!this.isTimedAssessmentSubmitted) {
              this.submitAssessment();
              clearInterval(this.timer);
            }
          }
        }.bind(this),
        1000
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.sp-timer__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align to bottom */
  align-items: flex-start;
  padding: 0px;
  width: 100px;
  height: 49px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 3px rgb(0 0 0 / 15%), 0px 1px 3px rgb(0 0 0 / 30%);

  .sp-timer__text {
    font-family: $font-family;
    margin-bottom: 8px;
    @include h6;
    width: 100%;
    letter-spacing: 3.5px;
  }

  .loader {
    background: $brand-primary-400;
    border-radius: 8px;
    transition: width 1s linear;
    width: 100%;
    height: 4px;
  }
  &.green {
    background: $brand-primary-100;
    .sp-timer__text {
      color: $brand-primary-400;
    }
  }

  &.orange {
    background: $brand-warning-100;
    .sp-timer__text {
      color: $brand-warning-400;
    }
  }

  &.red {
    background: $brand-error-100;
    .sp-timer__text {
      color: $brand-error-400;
    }
  }

  &.sp-timer__not_started {
    background: $brand-neutral-75;
    .sp-timer__text {
      color: $brand-neutral-300;
    }
    .loader {
      width: 0%;
    }
  }
}
</style>
