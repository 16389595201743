export default {
  data() {
    return {
      isShareCopied: false
    };
  },
  computed: {
    isAuthenticated() {
      return this.$keycloak.authenticated;
    }
  },
  methods: {
    downloadAwards(url) {
      window.open(url, "_blank");
    },
    shareAwards(url) {
      var input = document.createElement("input");
      input.setAttribute("value", url);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      if (this.options) {
        this.options[1].isLoading = true;
      }
      this.isShareCopied = true;
      setTimeout(() => {
        if (this.options) {
          this.options[1].isLoading = false;
        }
        this.isShareCopied = false;
      }, 5000);
    }
  }
};
