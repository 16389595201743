<template>
  <div>
    <div class="d-flex justify-content-center">
      <div
        :class="footerMenuClass(m)"
        v-for="(m, i) in menu"
        :key="i"
        @click="selectMenu(m)"
      >
        <div
          v-if="m.id !== 'more'"
          :class="['text-nowrap', { 'd-hide__mobile': !m.isActive }]"
        >
          <img
            :src="m.isActive ? m.iconActive : m.iconDefault"
            alt="icon"
            width="24"
            class="d-hide__mobile"
          />
          <img
            :src="m.isActive ? m.iconDefault : m.iconActive"
            alt="icon"
            width="24"
            class="d-none d-show__mobile--inline"
          />
          <span class="fm__wrapper-menu__title">
            <span v-if="m.isActive" class="hide-title">{{ m.title }}</span>
            <b-badge
              variant="light"
              class="fm__wrapper-menu-topic-count"
              v-if="m.id === 'forums' && getAllPostListCount > 0"
            >
              {{ getAllPostListCount }}
            </b-badge>
            <b-icon
              v-if="
                m.id === 'forums' &&
                  !m.isDisabled &&
                  hasUnreadPost &&
                  getAllPostListCount > 0
              "
              icon="dot"
              color="#0081FF"
              scale="2"
            ></b-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import openFeedbackForm from "@/core/mixins/openFeedbackForm.js";
import USER_LAYOUT_MODAL_IDS from "./UserLayout/constants";
export default {
  mixins: [openFeedbackForm],
  props: {
    variant: {
      type: String,
      default: "primary"
    }
  },
  data() {
    return {
      USER_LAYOUT_MODAL_IDS: {}
    };
  },
  created() {
    this.USER_LAYOUT_MODAL_IDS = USER_LAYOUT_MODAL_IDS;
  },
  computed: {
    ...mapGetters([
      "getCurrentMenuItem",
      "getTopicsList",
      "showDiscussionTopics",
      "forumsUserStatus",
      "getAllPostListCount"
    ]),
    menu() {
      let arr = [
        {
          title: this.$t("course.tab.learning"),
          id: "modules",
          iconDefault: require("@/assets/images/vle/learning.svg"),
          iconActive: require("@/assets/images/vle/learning-active.svg"),
          isActive: true,
          isDisabled: false,
          path: `/self-paced/${this.$route.params.id}`
        },
        {
          title: this.$t("course.footer.forums"),
          id: "forums",
          iconDefault: require("@/assets/images/vle/forums-disabled.svg"),
          iconActive: require("@/assets/images/vle/forums.svg"),
          isActive: false,
          isDisabled: false,
          path: `/self-paced/${this.$route.params.id}/forums`
        },
        {
          title: this.$t("teams.navigation.title"),
          id: "teams",
          iconDefault: require("@/assets/images/vle/teams.svg"),
          iconActive: require("@/assets/images/vle/teams.svg"),
          isActive: false,
          isDisabled: false,
          path: `/self-paced/${this.$route.params.id}/teams`
        }
      ];
      arr.forEach(a => {
        a.isActive = false;
        if (a.id === this.getCurrentMenuItem) {
          a.isActive = true;
        }
        if (a.id === "teams") {
          if (this.getTopicsList.length === 0) {
            a.isDisabled = true;
          }
        }
      });
      return arr.filter(a => !a.isDisabled);
    },
    hasUnreadPost() {
      const unreadArray = this.showDiscussionTopics.map(topic => {
        if (topic.categories.length === 0) {
          return topic.unread_count;
        } else {
          return topic.categories.reduce((total, category) => {
            return total + category.unread_count;
          }, 0);
        }
      });
      return unreadArray.some(count => count > 0);
    },
    footerMenuClass() {
      return m => {
        return {
          "vle__learning-forums-teams c-pointer": true,
          "fm__wrapper-menu__div": true,
          "fm__wrapper-menu__active": m.isActive,
          "fm__wrapper-menu__disabled": m.isDisabled,
          "fm__unread-count__space":
            m.id === "forums" &&
            !m.isDisabled &&
            this.hasUnreadPost &&
            this.getAllPostListCount > 0
        };
      };
    }
  },
  mounted() {
    this.getListOfTopics();
  },
  methods: {
    selectMenu(m) {
      if (m.isDisabled === false && m.id !== "more") {
        this.$store.commit("SET_CURRENT_MENU_ITEM", m.id);
        if (m.id !== "modules") {
          this.$store.commit("TOGGLE_VLE_SIDE_NAV", true);
        } else {
          this.$store.commit("TOGGLE_VLE_SIDE_NAV", false);
        }
        if (m.id === "teams" && !this.$route.path.includes("teams")) {
          this.$router.push({ name: "TeamsView" });
        }
      }
      if (m.id === "forums") {
        this.$store.dispatch("getForumsUserStatus");
      }

      if (m.id === "forums" || m.id === "modules") {
        this.$router
          .push({
            path: m.path
          })
          .catch(err => err);
      }
    },
    getListOfTopics() {
      let obj = {
        courseId: this.$route.params.id
      };
      this.$store.dispatch("getTopicsList", obj);
    },
    openDiscussionForumsModal() {
      this.$bvModal.show(this.USER_LAYOUT_MODAL_IDS.DISCUSSIONS_FORUMS_MODAL);
    }
  },
  watch: {
    forumsUserStatus: {
      handler(status) {
        if (status === 201) {
          this.openDiscussionForumsModal();
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.vle__learning-forums-teams + .vle__learning-forums-teams {
  margin-left: 32px;
}
.vle__learning-forums-teams {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: capitalize;
}
.fm__wrapper-menu__div {
  background: transparent;
  border-radius: 5px;
  padding: 8px 16px;
  position: relative;
  color: $brand-default-text;
  display: flex;
  align-items: center;
  .fm__wrapper-menu__title {
    margin-left: 8px;
  }
  &.fm__wrapper-menu__active {
    background: $brand-primary-100;
    border-radius: 8px;
    color: $brand-primary-lighter;
    p {
      color: $brand-primary-lighter;
    }
  }
  &.fm__wrapper-menu__disabled {
    cursor: not-allowed;
  }
  &.fm__unread-count__space {
    padding: 8px 26px 8px 16px;
  }
  .fm__wrapper-menu-topic-count {
    background: $brand-primary;
    color: $brand-primary-50;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    mix-blend-mode: normal;
    border-radius: 4px;
    margin-left: 4px;
  }
  .b-icon.bi {
    position: absolute;
    top: 4px;
    right: 8px;
  }
}
[dir="rtl"] {
  .vle__learning-forums-teams + .vle__learning-forums-teams {
    margin-left: 0px;
    margin-right: 32px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 960px) {
  .fm__wrapper-menu__div {
    .fm__wrapper-menu__title {
      margin-left: 0px;
      .hide-title {
        display: none;
      }
    }
  }
}
</style>
