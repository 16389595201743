import store from "@/store";
import { LxpLoader } from "didactica";
export default {
  data() {
    return {
      isPageLoading: false
    };
  },
  components: {
    LxpLoader
  },
  methods: {
    async isUserEnrolled() {
      this.isPageLoading = true;
      const data = await this.$store.dispatch("getACourse", {
        course_id: this.$route.params.id,
        version: "v1"
      });
      if (!data?.is_enrolled) {
        this.isPageLoading = false;
        this.$router.push({
          name: "Courses Detail",
          params: { id: this.$route.params.id }
        });
      } else {
        this.isPageLoading = false;
      }
    }
  },
  mounted() {
    const selectedCourse = store.getters.getSelectedCourse;
    if (selectedCourse && Object.keys(selectedCourse).length === 0) {
      this.isUserEnrolled();
    }
  }
};
