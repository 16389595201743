<template>
  <div class="h-100 container-fluid p-0">
    <b-container
      fluid
      :class="[
        'ul-container h-100',
        {
          'ul-container__tour': vueTourStatus,
          'ul-container__fullscreen': isFullScreenEnabled,
          'ul-container__exitscreen': isExitScreenEnabled,
          'ul-container__alert': isSystemAlertEnabled
        }
      ]"
      style="padding:0px"
    >
      <SystemAlert v-if="isSystemAlertEnabled" isFixed />
      <div class="ul-header">
        <b-row
          :class="[
            'ul-header__row',
            {
              'ul-header__fade-out':
                hideNavbar && !vueTourStatus && !isVleSideNavOpen
            }
          ]"
        >
          <b-col cols="4" sm="2" lg="3" md="2" class="text-left pm-0">
            <div class="ul-header__left">
              <div
                class="ul-header__exit d-hide__mobile"
                id="selfPlacedExit"
                v-b-modal.cancel-modal
              >
                <img
                  src="@/assets/images/vle/exit-icon.svg"
                  class="c-pointer"
                />
                <p class="ul-exit">
                  {{ $t("vle.exit_vle") }}
                </p>
              </div>
              <span
                class="ul-header__b1 d-hide__mobile"
                v-if="$route.name !== 'ViewPost'"
              ></span>
              <div
                class="ul-header__modules"
                id="selfPlacedNavbarIcon"
                v-if="$route.name !== 'ViewPost'"
              >
                <div
                  :class="[
                    'ul-header__modules--div',
                    {
                      'ul-header__modules--open': isVleSideNavOpen,
                      'ul-header__forums--open': getCurrentMenuItem === 'forums'
                    }
                  ]"
                  @click="$store.commit('TOGGLE_VLE_SIDE_NAV')"
                >
                  <img
                    :src="moduleListSrc"
                    alt="modules"
                    v-b-tooltip.hover
                    :title="tooltipTitle"
                  />
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="4" sm="4" lg="5" md="6">
            <FooterMenu variant="primary" id="selfPlacedHeaderTabs" />
          </b-col>
          <b-col
            cols="4"
            lg="3"
            md="3"
            :class="[
              'pm-0',
              {
                'ul-header__timer': showTimedAssessment
              }
            ]"
          >
            <div
              :class="[
                'd-flex justify-content-end align-items-center mobile-content__start',
                {
                  'ul-header__timer': showTimedAssessment
                }
              ]"
            >
              <div
                v-if="getCurrentMenuItem === 'modules'"
                :class="{
                  'ul-header__timer': showTimedAssessment
                }"
              >
                <TimerComponent
                  v-if="getTimedAssessmentFlag"
                  class="sp-header__timer-component"
                  :duration="getTimedData.duration"
                  :state="getTimedData.state"
                  @onFinish="autoSubmitAssessment"
                /><TopNav
                  v-else
                  @onFinish="handleOnFinish"
                  class="d-hide__mobile"
                >
                </TopNav>
              </div>
              <LxpButton
                @click="showAddPostForm"
                v-if="getCurrentMenuItem === 'forums'"
                class="d-hide__mobile add-post__btn"
              >
                {{ $t("discussions.post.add") }}
              </LxpButton>
              <div
                class="ul-header__right"
                id="expandWalkthrough"
                ref="expandWalkthrough"
              >
                <div
                  class="ul-header__expand d-hide__mobile"
                  @click="toggleFullScreen"
                >
                  <img
                    :src="
                      isFullScreenEnabled
                        ? require('@/assets/images/vle/exit-full-screen.svg')
                        : require('@/assets/images/vle/full-screen.svg')
                    "
                    alt="expand"
                    id="fullscreen"
                  />
                  <b-tooltip target="fullscreen" triggers="hover">
                    <div v-if="isFullScreenEnabled">
                      {{ $t("vle.full_screen.exit_fullscreen") }}
                      <span class="ul-header__expand--tooltip"
                        ><img
                          src="~@/assets/images/vle/keyboard.svg"
                        />Esc</span
                      >
                    </div>
                    <span v-else>{{
                      $t("vle.full_screen.enter_fullscreen")
                    }}</span>
                  </b-tooltip>
                </div>
                <div
                  class="vle__header-custom-dropdown selfpaced__menu--btn-focus d-hide__mobile"
                >
                  <b-dropdown
                    right
                    variant="link"
                    toggle-class="text-decoration-none"
                    id="walkthrowDropdown"
                    ref="walkthrowDropdown"
                    class="custom-dropdown"
                    no-caret
                  >
                    <template #button-content>
                      <Icon
                        iconName="three-dots-vertical"
                        class="three-dots-vertical"
                        :rounded="false"
                        variant="dark"
                        id="selfPlacedMenuIcon"
                      >
                      </Icon>
                    </template>
                    <template id="walkthrowDropdownItems">
                      <b-dropdown-item
                        class="custom-dropdown-item"
                        @click="goToCouresData"
                      >
                        <img
                          src="@/assets/images/wrapper-components/alert/information.svg"
                          alt="about"
                        />
                        <span class="vle__list-title">{{
                          $t("vle.vle_menu.about")
                        }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="startTour"
                        class="custom-dropdown-item"
                      >
                        <img
                          src="@/assets/images/icons/walkthrough.svg"
                          alt="walkthrough"
                        />
                        <span class="vle__list-title">{{
                          $t("vle.vle_menu.walkthrough")
                        }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="unenroll custom-dropdown-item"
                        v-b-modal.unenroll-modal
                        v-if="
                          getSelectedCourse.is_enrolled &&
                            isCourseStarted === false &&
                            !getSelectedCourse.course_completed &&
                            !isCourseEnded
                        "
                      >
                        <div class="ac-mc__list" style="color:#FF4040;">
                          <img
                            src="@/assets/images/icons/unenroll-module.svg"
                            alt="unenroll"
                            class=" unenroll-image"
                          />
                          <span class="vle__list-title">{{
                            $t("course.unenroll.title")
                          }}</span>
                        </div>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </div>
                <!-- vle mobile screen header -->
                <div class="d-show__mobile">
                  <VleMobileHeader
                    @walkThorugh="startTour"
                    :unEnroll="showUnenroll"
                  />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-row
        :class="[
          'ul-content',
          {
            'ul-content__scorm': isScormUnit && getCurrentMenuItem === 'modules'
          }
        ]"
        id="ul-content__main"
      >
        <b-col md="12" class="ul-bottom__col p-0">
          <slot name="bottom-section"></slot>
        </b-col>
      </b-row>
    </b-container>
    <Modals @startTour="startTour" @navigateTo="navigateTo"></Modals>
    <Toast
      id="fullscreen-toast"
      :title="$t('vle.full_screen.title')"
      :imgPath="require('@/assets/images/vle/timer/timer-info.svg')"
      :description="$t('vle.full_screen.description')"
      :autoHide="false"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { LxpButton } from "didactica";
// components
import TopNav from "@/components/SelfPaced/TopNav.vue";
import FooterMenu from "@/components/SelfPaced/FooterMenu.vue";
import TimerComponent from "@/components/SelfPaced/TimerComponent.vue";
import Modals from "@/components/SelfPaced/UserLayout/Modals.vue";
import Toast from "../SelfPaced/Toast.vue";
import SystemAlert from "@/views/SystemAlert.vue";
import VleMobileHeader from "@/components/SelfPaced/VleMobileHeader.vue";
//Mixins
import openFeedbackForm from "@/core/mixins/openFeedbackForm.js";
import timedAssessment from "../SelfPaced/mixins/timedAssessment";
import goToCouresData from "@/components/SelfPaced/mixins/userLayout";
import courseCompletion from "@/components/SelfPaced/mixins/courseCompletion.js";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";

export default {
  mixins: [
    openFeedbackForm,
    timedAssessment,
    goToCouresData,
    courseCompletion,
    discussionViewSelectorMixin
  ],
  components: {
    LxpButton,
    TopNav,
    FooterMenu,
    TimerComponent,
    Modals,
    Toast,
    SystemAlert,
    VleMobileHeader
  },
  data() {
    return {
      search: "",
      hover: false,
      isFullScreenEnabled: false,
      hideNavbar: false,
      isExitScreenEnabled: false
    };
  },
  computed: {
    ...mapGetters([
      "language",
      "getAuthProfile",
      "getTimedData",
      "getCourseViewSelector",
      "isFirstSequence",
      "isFirstUnit",
      "getCurrentMenuItem",
      "getTimedAssessmentFlag",
      "vleUserStatus",
      "getCurrentSequenceId",
      "vueTourStatus",
      "isVleSideNavOpen",
      "isScormUnit",
      "isSystemAlertEnabled"
    ]),
    isCourseEnded() {
      return moment(this.currentServerTime).isAfter(
        moment(this.getSelectedCourse.course_ends_at)
      );
    },
    isCourseStarted() {
      if (this.getSelectedCourse.sections) {
        return this.getSelectedCourse.sections.some(a => a.resumeBlock);
      }
      return false;
    },
    moduleListSrc() {
      let path;
      if (this.isVleSideNavOpen) {
        this.getCurrentMenuItem === "modules"
          ? (path = require("@/assets/images/vle/modules-close-white.svg"))
          : (path = require("@/assets/images/vle/bulleted-icon.svg"));
      } else {
        this.getCurrentMenuItem === "modules"
          ? (path = require("@/assets/images/vle/modules-blue.svg"))
          : (path = require("@/assets/images/vle/bulleted-icon.svg"));
      }
      return path;
    },
    tooltipTitle() {
      let title;
      if (this.getCurrentMenuItem === "modules") {
        title = this.$t("vle.tooltip.modules_index");
      } else if (this.getCurrentMenuItem === "forums") {
        title = this.$t("vle.tooltip.topics_list");
      } else if (this.getCurrentMenuItem === "teams") {
        title = this.$t("vle.tooltip.teams_list");
      }
      return title;
    },
    showTimedAssessment() {
      return (
        this.getCurrentMenuItem === "modules" && this.getTimedAssessmentFlag
      );
    },
    showUnenroll() {
      return (
        this.getSelectedCourse.is_enrolled &&
        this.isCourseStarted === false &&
        !this.getSelectedCourse.course_completed &&
        !this.isCourseEnded
      );
    }
  },
  mounted() {
    if (
      !(
        this.$keycloak &&
        this.$keycloak.idTokenParsed &&
        this.$keycloak.idTokenParsed.sub
      )
    ) {
      if (!["ViewPost", "Courses Detail"].includes(this.$route.name)) {
        this.$router.push({ name: "Home" });
      }
    } else {
      if (Object.keys(this.getAuthProfile).length === 0) {
        this.$store.dispatch("getAuthProfile");
      }
    }
    if (this.$route.name === "Self Paced") {
      this.$store.dispatch("getVLEUserStatus");
    }
    [
      "fullscreenchange",
      "webkitfullscreenchange",
      "mozfullscreenchange",
      "msfullscreenchange"
    ].forEach(eventType =>
      document.addEventListener(eventType, this.onFullScreenChange, false)
    );
  },
  watch: {
    vleUserStatus: {
      handler(status) {
        if (status) {
          this.openWalkthroughModal();
        }
      },
      deep: true
    }
  },
  methods: {
    startTour() {
      this.$emit("startTour");
    },
    openWalkthroughModal() {
      this.$bvModal.show("walkthrough-modal");
    },
    logOut() {
      localStorage.removeItem("onboardingStatus");
      const redirectUri =
        this.$keycloak.endpoints.logout() +
        "?id_token_hint=" +
        this.$keycloak.idToken +
        "&post_logout_redirect_uri=" +
        encodeURIComponent(window.location.origin);
      window.location.href = redirectUri;
    },
    navigateTo(path) {
      this.$router.push({ path: path }).catch(() => {});
    },
    toggleFullScreen() {
      let elem = document.documentElement;
      this.isExitScreenEnabled = true;
      if (!document.fullscreenElement) {
        elem.requestFullscreen();
        if (localStorage.getItem("isFirstFullScreenClick") === null) {
          this.$bvToast.show("fullscreen-toast");
          localStorage.setItem("isFirstFullScreenClick", true);
        }
      } else {
        document.exitFullscreen();
      }
    },
    onFullScreenChange() {
      this.isFullScreenEnabled = document.fullscreenElement;
      this.isFullScreenEnabled
        ? (this.hideNavbar = true)
        : (this.hideNavbar = false);
    },
    showAddPostForm() {
      this.$store.commit("RESET_SELECTED_DISCUSSION_POST");
      this.$store.commit(
        "SET_DISCUSSION_VIEW_SELECTOR",
        this.DISCUSSION_VIEW_SELECTOR.AddPostForm
      );
      if (this.$route.params.topic_id) {
        this.$router
          .push({
            name: "TopicPost",
            params: { topic_id: this.$route.params.topic_id }
          })
          .catch(err => err);
      } else {
        this.$router
          .push({
            name: "AddPost"
          })
          .catch(err => err);
      }
    }
  }
};
</script>
<style lang="scss">
@import "../../scss/v-tour";
$position-in-px: 20px;

.ul-container {
  &.ul-container__exitscreen {
    animation: fullscreen-exit-fade-in 0.5s ease-in forwards;
  }
  &.ul-container__fullscreen {
    animation: fullscreen-enter-fade-in 0.5s ease-in forwards;
  }
  &.ul-container__tour {
    animation: none;
  }
  .ul-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
    .ul-header__row {
      margin: 0;
      background: #ffffff;
      padding: 4px 16px;
      box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
      @include horizontal-space-between;
      &.ul-header__fade-out {
        transition: background 0.75s ease-in-out;
        background: $brand-neutral-900;
        * {
          opacity: 0;
          transition: opacity 0.75s ease-in-out;
        }
        .ul-header__timer,
        .sp-header__timer-component,
        .sp-header__timer-component * {
          opacity: 1;
        }
        &:hover {
          background: #ffffff;
          * {
            opacity: 1;
          }
        }
      }
      .ul-header__left {
        display: flex;
        align-items: center;
        .ul-header__exit {
          @include flex-horizontal-center;
          margin-right: 24px;
          .ul-exit {
            @include button-label;
            padding-left: 8px;
            margin-bottom: 0;
            color: $brand-primary;
          }
        }
        .ul-header__b1 {
          width: 1px;
          height: 40px;
          background: $brand-neutral-200;
        }
        .ul-header__modules {
          margin-left: 24px;

          .ul-header__modules--div {
            width: 40px;
            height: 40px;
            padding: 8px 0px;
            border-radius: 8px;
            background: $brand-primary-100;
            @include flex-horizontal-center;
            cursor: pointer;
            &:hover {
              img {
                filter: invert(35%) sepia(36%) invert(37%) sepia(58%)
                  saturate(5230%) hue-rotate(198deg) brightness(104%)
                  contrast(104%);
              }
            }
            &.ul-header__modules--open {
              background: $brand-primary;
              &:hover {
                img {
                  filter: none;
                }
              }
              &.ul-header__forums--open {
                background: $brand-primary-100;
              }
            }
          }
        }
      }
      .vle__header-custom-dropdown {
        margin-top: 4px;
        padding: 0px 4px 0px 24px;
        .dropdown {
          &.show {
            .btn.dropdown-toggle {
              .three-dots-vertical {
                background: $brand-primary-100;
              }
            }
          }
        }
        .dropdown-menu {
          background: #ffffff;
          top: 13px !important;
          box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
          border-radius: 8px;
          border: none;
          padding: 0;
          width: 214px;

          .custom-dropdown-item {
            @include flex-horizontal-center;
            z-index: 2;
            cursor: pointer;

            &:hover,
            &:active,
            &:focus {
              background: $brand-primary-100;
              .dropdown-item {
                .vle__list-title {
                  font-weight: 500;
                }
              }
            }
            &:active,
            &:focus {
              .dropdown-item {
                .vle__list-title {
                  color: $brand-primary-400;
                }
                img {
                  filter: invert(35%) sepia(36%) invert(37%) sepia(58%)
                    saturate(5230%) hue-rotate(198deg) brightness(104%)
                    contrast(104%);
                }
              }
            }

            &:first-child {
              border-top-left-radius: 9px;
              border-top-right-radius: 8px;
            }
            &:last-child {
              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 8px;
            }

            .dropdown-item {
              padding: 16px 8px;
              display: flex;
              align-items: center;
              &:hover,
              &:active,
              &:focus {
                text-decoration: none;
                background-color: transparent;
              }

              img {
                width: 20px;
                height: 20px;
              }
              .vle__list-title {
                margin-left: 10px;
                @include body-regular;
              }
            }

            &.unenroll {
              &:hover,
              &:active,
              &:focus {
                background: $brand-error-100;
                img {
                  filter: none;
                }
                .vle__list-title {
                  color: $brand-error-400;
                }
              }
              .vle__list-title {
                color: $brand-error-400;
              }
            }
          }
        }

        .btn.dropdown-toggle {
          padding: 0px !important;
          &.focus {
            box-shadow: none !important;
          }
          .three-dots-vertical {
            padding: 8px;
            width: 40px;
            height: 40px;
            background: $brand-neutral-50;
            border-radius: 8px;
            @include flex-horizontal-center;

            &:active {
              background: $brand-primary-100;
            }
          }
        }
      }
      .ul-header__right {
        @include flex-horizontal-center;
        .ul-header__expand {
          width: 40px;
          height: 40px;
          padding: 8px;
          margin-left: 24px;
          cursor: pointer;
        }
      }
      .add-post__btn {
        white-space: nowrap;
      }
    }
  }
  .ul-content {
    padding-top: 58px;
    overflow: hidden auto;
    height: 100%;
    background: $brand-primary-50;
    position: relative;
    &.row {
      margin: 0;
    }
    &.ul-content__scorm {
      overflow: hidden;
    }
  }
  &.ul-container__tour {
    &.ul-container__alert {
      .system-alert {
        z-index: 9;
      }
      .ul-header {
        margin-top: 58px;
      }
    }
    .ul-header {
      position: static;
    }
  }
  &:not(.ul-container__tour) {
    &.ul-container__alert {
      .ul-header {
        top: 40px;
      }
      .ul-content {
        padding-top: 98px;
      }
      .add__post--wrapper {
        top: 40px;
        height: calc(100% - 40px);
      }
      .view__post--wrapper {
        top: 94px;
        height: calc(100% - 94px);
      }
    }
  }
}

.btn-outline-danger:hover {
  background-color: none !important;
}

.ul-header__expand--tooltip {
  color: $brand-primary-400;
  display: inline-block;
  margin-left: 16px;
  img {
    margin-right: 4px;
  }
}

@keyframes fullscreen-enter-fade-in {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes fullscreen-exit-fade-in {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
[dir="rtl"] {
  .sp-header {
    .sp-discussion__btns {
      margin-left: 1rem;
    }
  }

  .vle__header-custom-dropdown {
    padding: 0px 24px 0px 4px;
    .vle__list-title {
      margin-left: 0px;
      margin-right: 10px;
    }

    .dropdown-item {
      text-align: right;
    }
  }
}

.vle-mobile__header {
  display: none;
}
@media only screen and (max-width: 960px) {
  .ul-header__left {
    .ul-exit {
      display: none;
    }
  }
}
@media only screen and (max-width: 767px) {
  .vle__learning-forums-teams {
    margin-left: 0px !important;
  }
  .ul-header__row {
    .footer-content {
      width: 100px !important;
    }
    .add-post__btn {
      padding: 8px 10px !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .ul-header__row {
    flex-direction: row-reverse;
    .ul-header__left {
      justify-content: end;
    }
    .fm__wrapper-menu__div {
      margin-left: 0px;
      &.fm__wrapper-menu__active {
        background: none;
        color: $brand-primary;
      }
    }
    // hide elements below 600px screens
    .d-hide__mobile {
      display: none !important;
    }
    // show elements below 600px screens
    .d-show__mobile {
      display: block !important;
    }
    .d-show__mobile--inline {
      display: inline !important;
    }
  }
  .ul-header {
    .pm-0 {
      padding: 0px;
    }
    .mobile-content__start {
      justify-content: start !important;
    }
  }
}
</style>
