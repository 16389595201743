<template>
  <div class="teams-left-nav__wrapper">
    <Breadcrumb :items="breadcrumb"></Breadcrumb>
    <img src="@/assets/images/teams/group-avatar.png" class="w-100" />
    <h3 class="left-nav__heading">{{ $t("teams.intro.heading") }}</h3>
    <p class="left-nav__content">
      {{ $t("teams.intro.content") }}
    </p>
    <div class="ln-instruction__section">
      <b-row class="ln-instruction__steps">
        <b-col md="2">
          <img src="@/assets/images/teams/members.svg" width="auto" />
        </b-col>
        <b-col md="10">
          <span>{{ $t("teams.intro.steps.first") }}</span>
        </b-col>
      </b-row>
      <hr class="solid" />
      <b-row class="ln-instruction__steps">
        <b-col md="2">
          <img src="@/assets/images/teams/add-post.svg" width="auto" />
        </b-col>
        <b-col md="10">
          <span>{{ $t("teams.intro.steps.second") }}</span>
        </b-col>
      </b-row>
      <hr class="solid" />
      <b-row class="ln-instruction__steps">
        <b-col md="2">
          <img src="@/assets/images/teams/message.svg" width="auto" />
        </b-col>
        <b-col md="10">
          <span>{{ $t("teams.intro.steps.third") }}</span>
        </b-col>
      </b-row>
    </div>
    <div class="left-nav__note">
      <span class="left-nav__note"> *{{ $t("teams.intro.note") }} </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    courseTitle: {
      type: String,
      default: "Courseware"
    }
  },
  computed: {
    breadcrumb() {
      return [
        {
          text: this.courseTitle,
          href: "javascript:;"
        },
        {
          text: this.$t("teams.heading"),
          active: true
        }
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
.teams-left-nav__wrapper {
  padding-bottom: 32px;
  .left-nav__heading {
    line-height: 40px;
    font-size: 32px;
    text-align: center;
    color: $brand-public-primary;
    margin: 10px 14px;
  }
  .left-nav__content {
    @include label-medium;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: $brand-public-secondary;
  }
  .ln-instruction__section {
    background: #f7fafe;
    border-radius: 12px;
    margin-top: 16px;
    .solid {
      width: 80%;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .ln-instruction__steps {
      padding: 12px 32px;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #44464e;
      }
    }
  }
  .left-nav__note {
    text-align: center;
    padding: 16px 0px;
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #44464e;
    }
  }
}
@media screen and (min-width: 1400px) {
  .teams-left-nav__wrapper {
    .left-nav__heading {
      margin: 32px 16px;
    }
    .left-nav__content {
      font-size: 14px;
    }
    .ln-instruction__section {
      margin-top: 32px;
      padding: 16px 0px;
      .solid {
        width: 80%;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      .ln-instruction__steps {
        padding: 32px;
        span {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
