var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{class:[
      'sp-header',
      {
        'sp-header__modules': _vm.getCurrentMenuItem === 'modules',
        'sp-header__scorm': _vm.isScormUnit && _vm.getCurrentMenuItem === 'modules',
        'left-panel__hide': !_vm.isVleSideNavOpen
      }
    ]},[_c('nav',{class:{
        active: !_vm.isToggleOpen,
        forums_sidebar: _vm.getCurrentMenuItem === 'forums'
      },attrs:{"id":"sidebar"}},[_c('LeftNav',{on:{"selectedCategory":function($event){return _vm.$emit('selectedCategory', $event)}}})],1),(_vm.getCurrentMenuItem === 'modules')?_c('div',{staticClass:"sp-header__bg",attrs:{"id":"sp-header__bg"},on:{"click":function($event){return _vm.$store.commit('TOGGLE_VLE_SIDE_NAV')}}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }