<template>
  <div class="milestones-wrapper">
    <div
      class="mw-card"
      v-for="(m, i) in milestones"
      :key="i"
      @click="navigateTo(m.course_key_string)"
    >
      <img :src="m.course_image_url" alt="course image" />
      <div class="mw-card__right">
        <p>{{ m.display_name }}</p>
        <span>{{ m.org.slug }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["milestones"])
  },
  methods: {
    navigateTo(id) {
      this.$router.push({ path: `/coursewares/${id}` });
    }
  }
};
</script>
<style lang="scss" scoped>
.milestones-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .mw-card {
    border-radius: 16px;
    background: $brand-neutral-0;
    box-shadow: 3px 3.5px 20px 10px rgba(0, 129, 255, 0.15);
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
    .mw-card__right {
      text-align: left;
      p {
        @include subtitle-small($brand-neutral-900);
        margin-bottom: 10px;
      }
      span {
        @include label-medium;
        color: $brand-neutral-700;
      }
    }
  }
}
</style>
